import { configureStore } from "@reduxjs/toolkit";
import { usermgmtSlice } from "./Reducers/usermgmtSlice";
import { dashboardSlice } from "./Reducers/DashboardSlice";
import { notificationSlice } from "./Reducers/notificationSlice";
import { reportsSlice } from "./Reducers/reportsSlice";
import { eventSlice } from "./Reducers/EventSlice";
import { supportSlice } from "./Reducers/supportSlice";
import { profileshareSlice } from "./Reducers/profileShare";
import { SubscribeSlice } from "./Reducers/SubscribedSlice";


export const store = configureStore({
  reducer: {
    usersdata: usermgmtSlice.reducer,
    dashdata: dashboardSlice.reducer,
    notificationData: notificationSlice.reducer,
    reportsData: reportsSlice.reducer,
    eventsData: eventSlice.reducer,
    supportData : supportSlice.reducer,
    profileData:profileshareSlice.reducer,
    Subscribeddata:SubscribeSlice.reducer

  },
});
