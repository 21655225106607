import React from "react";
import { Link } from "react-router-dom";

export default function TermsAndConditionsSpanish() {
  return (
    <div>
      <div className="container">
        <div className="privacy_text">
          {/* <div className="header-logo">
    <Link className="terms-and-conditions link" to="#">
      <img
        className="terms-and-conditions-logo"
        src={require("product-hotspot/images/logo-1.0.svg")}
        alt="logo"
      />
    </Link>
  </div> */}
          <h3>Términos y Condiciones</h3>
          <p>
            ¡Bienvenido a URush! Estos Términos y Condiciones rigen su uso de la
            plataforma y servicios de URush. Al acceder o utilizar URush, usted
            acepta cumplir con estos Términos y Condiciones.
          </p>
          <h4>1. Registro de Cuenta</h4>
          <p>
            a. Para acceder a ciertas características de URush, es posible que
            se le requiera registrarse para obtener una cuenta.
          </p>
          <p>
            b. Usted acepta proporcionar información precisa, actual y completa
            durante el proceso de registro.
          </p>
          <p>
            c. Usted es responsable de mantener la confidencialidad de sus
            credenciales de cuenta y de todas las actividades que ocurran bajo
            su cuenta.
          </p>

          <h4>2. Uso Aceptable:</h4>
          <p>
            a. Usted acepta utilizar URush únicamente para fines legales y de
            acuerdo con estos Términos y Condiciones.
          </p>
          <p>
            b. Usted no participará en ninguna actividad que interfiera o
            interrumpa el funcionamiento de URush o sus servicios asociados.
          </p>
          <p>
            c. Usted no utilizará URush para transmitir ningún contenido que sea
            ilegal, difamatorio, obsceno, ofensivo, o que infrinja los derechos
            de otros.
          </p>

          <h4>3. Propiedad Intelectual:</h4>
          <p>
            a. URush y su contenido, incluidos, entre otros, texto, gráficos,
            logotipos, imágenes y software, están protegidos por derechos de
            autor, marcas comerciales y otras leyes de propiedad intelectual.
          </p>
          <p>
            b. Usted acepta no modificar, reproducir, distribuir ni crear obras
            derivadas basadas en el contenido de URush sin el consentimiento
            previo por escrito de URush.
          </p>
          <h4>4. Privacidad:</h4>

          <p>
            a. Su privacidad es importante para nosotros. Consulte nuestra
            Política de Privacidad para obtener información sobre cómo
            recopilamos, utilizamos y divulgamos su información personal.
          </p>
          <p>
            b. Al utilizar URush, usted consiente la recopilación y el uso de su
            información como se describe en nuestra Política de Privacidad.
          </p>

          <h4>5. Enlaces a Terceros:</h4>
          <p>
            a. URush puede contener enlaces a sitios web o servicios de terceros
            que no son propiedad ni están controlados por URush.
          </p>
          <p>
            b. URush no tiene control sobre, y no asume ninguna responsabilidad
            por, el contenido, las políticas de privacidad o las prácticas de
            cualquier sitio web o servicio de terceros.
          </p>
          <p>
            c. Usted accede a sitios web o servicios de terceros bajo su propio
            riesgo y debe revisar los términos y políticas aplicables.
          </p>
          <h4>6. Limitación de Responsabilidad:</h4>
          <p>
            a. En la medida máxima permitida por la ley, URush y sus afiliados,
            funcionarios, directores, empleados y agentes no serán responsables
            de ningún daño directo, indirecto, incidental, especial o
            consecuente que surja de o esté relacionado de alguna manera con su
            uso de URush.
          </p>
          <p>
            b. La responsabilidad total de URush, en cualquier circunstancia,
            estará limitada al monto pagado por usted, si alguno, para acceder a
            URush.
          </p>
          <h4>7. Indemnización:</h4>
          <p>
            Usted acepta indemnizar y mantener a URush y sus afiliados,
            funcionarios, directores, empleados y agentes indemnes de y contra
            cualquier reclamación, responsabilidad, daños, pérdidas y gastos,
            incluidos los honorarios razonables de abogados, que surjan de o
            estén relacionados de alguna manera con su uso de URush o violación
            de estos Términos y Condiciones.
          </p>

          <h4>8. Cambios a los Términos y Condiciones:</h4>
          <p>
            URush se reserva el derecho de modificar o reemplazar estos Términos
            y Condiciones en cualquier momento. Usted es responsable de revisar
            regularmente estos Términos y Condiciones para ver si hay
            actualizaciones.
          </p>

          <h4>9. Ley Aplicable:</h4>
          <p>
            Estos Términos y Condiciones se regirán e interpretarán de acuerdo
            con las leyes de [Jurisdicción], sin tener en cuenta sus principios
            de conflicto de leyes.
          </p>

          <h4>10. Contáctenos:</h4>
          <p>
            Si tiene alguna pregunta o inquietud sobre estos Términos y
            Condiciones o su uso de URush, por favor contáctenos en{" "}
            <Link to="mailto:businessurush@gmail.com">
              businessurush@gmail.com
            </Link>
            .
          </p>

          <p>
            Al utilizar URush, usted acepta los términos descritos en estos
            Términos y Condiciones.
          </p>
        </div>
      </div>
    </div>
  );
}
