import { createSlice } from "@reduxjs/toolkit";
import { Dashboarddata } from "../Actions/Adminactions";



export const dashboardSlice = createSlice({
    name:"dashboardSlice",
    initialState:{
        dashboarddata:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(Dashboarddata.fulfilled,(state,action)=>{
            state.dashboarddata = action.payload

        })
    }
})

export default dashboardSlice.reducer