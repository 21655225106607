import { createSlice } from "@reduxjs/toolkit";
import { eventById, eventshare, getEvents } from "../Actions/Adminactions";
import { toast } from "react-toastify";

export const eventSlice = createSlice({
  name: "reportSlice",
  initialState: {
    eventdata: "",
    eventdatabyid: "",
    eventsahre:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvents.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.eventdata = action.payload;
      } else {
        toast.error(action.payload.error);
      }
    });
    builder.addCase(eventById.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.eventdatabyid = action.payload;
      } else {
        toast.error(action.payload.error);
      }
    });
    builder.addCase(eventshare.fulfilled, (state, action) => {
      if (action?.payload?.success) {
        state.eventsahre = action.payload;
      } else {
        toast.error(action.payload.error);
      }
    });
  },
});

export default eventSlice.reducer;
