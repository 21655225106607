import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Users() {
  const dashboardData = useSelector((state) => state.dashdata.dashboarddata);
  console.log(dashboardData)


   const data = {
    labels: ["Active ", "Inactive "],
    datasets: [
      {
        label: "# of Votes",
        data: [ "30","34"],
        backgroundColor: ["#6916AE", "#E03D84"],
  
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut className="gender-chart" data={data} />;
}
