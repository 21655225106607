import axios from "axios"


const Api =  axios.create({
    baseURL: process.env.REACT_APP_ADMIN_BASE_URL,
    headers: { 'authorization': `${sessionStorage.getItem('token')}`}
})

// Add a response interceptor
Api.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    return Promise.reject(error);
  });

  export default  Api; 