import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
  return (
    <div>
      <div className="container">
        <div className="privacy_text">
          {/* <div className="header-logo">
            <Link className="terms-and-conditions link" to="#">
              <img
                className="terms-and-conditions-logo"
                src={require("product-hotspot/images/logo-1.0.svg")}
                alt="logo"
              />
            </Link>
          </div> */}
          <h3>Privacy Policy</h3>
          <p>
            At URush, we are committed to protecting the privacy and security of
            your personal information. This Privacy Policy outlines the types of
            personal information we collect, how we use it, and the measures we
            take to safeguard it.
          </p>

          <h3>1. Information We Collect:</h3>
          <h4>a. Personal Information:</h4>
          <p>
            When you sign up for URush, we may collect personal information such
            as your name, email address, phone number, and company details. We
            may also collect additional information you choose to provide, such
            as your job title and preferences.
          </p>
          <h4>b. Usage Information:</h4>
          <p>
            We automatically collect certain information about your use of
            URush, including your IP address, device information, browser type,
            and operating system.
          </p>
          <h4>c. Cookies:</h4>
          <p>
            We use cookies and similar tracking technologies to enhance your
            experience and gather information about how you interact with our
            platform.
          </p>
          <h3>2. How We Use Your Information:</h3>

          <h4>a. Providing Services:</h4>
          <p>
            We use your personal information to provide you with access to URush
            and its features. Your information helps us customize and improve
            our services to better meet your needs.
          </p>
          <h4>b. Communication:</h4>
          <p>
            We may use your contact information to send you important updates,
            notifications, and marketing communications related to URush.
          </p>

          <h4>c. Analytics:</h4>
          <p>
            We analyze usage patterns and trends to improve the performance and
            functionality of URush. This includes monitoring user engagement,
            troubleshooting technical issues, and conducting research.
          </p>
          <h3>3. Sharing Your Information:</h3>

          <h4>a. Third-Party Service Providers:</h4>
          <p>
            We may share your information with trusted third-party service
            providers who assist us in operating our platform, conducting
            business, and delivering services to you.
          </p>

          <h4>b. Legal Compliance:</h4>
          <p>
            We may disclose your information to comply with applicable laws,
            regulations, legal processes, or enforceable governmental requests.
          </p>
          <h3>4. Data Security:</h3>
          <p>
            a. We employ industry-standard security measures to protect your
            personal information from unauthorized access, alteration,
            disclosure, or destruction.
          </p>
          <p>
            b. Despite our best efforts, no method of transmission over the
            internet or electronic storage is completely secure. Therefore, we
            cannot guarantee absolute security.
          </p>

          <h3>5. Your Choices:</h3>
          <p>
            a. You have the right to access, update, or delete your personal
            information at any time.
          </p>
          <p>
            b. You can opt-out of receiving marketing communications from us by
            following the instructions provided in the messages.
          </p>
          <h3>6. Children's Privacy:</h3>
          <p>
            URush is not intended for use by individuals under the age of 18. We
            do not knowingly collect personal information from children.
          </p>
          <h3>7. Changes to This Privacy Policy:</h3>
          <p>
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <h3>8. Contact Us:</h3>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at{" "}
            <Link to="mailto:businessurush@gmail.com">
              businessurush@gmail.com
            </Link>
            .
          </p>

          <p>
            By using URush, you agree to the terms outlined in this Privacy
            Policy.
          </p>
        </div>
      </div>
    </div>
  );
}
