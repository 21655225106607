import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import {
  changeReportStatus,
  getreportdetails,
} from "../Redux/Actions/Adminactions";

export default function ReportDetails() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [flag, setflag] = useState(true);
  const data = useSelector((state) => state.reportsData.reportbyid);
  console.log(data);

  useEffect(() => {
    dispatch(getreportdetails(location.state.id));
  }, [location.state.id, flag]);

  console.log(data?.data?.status);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Report Details</h2>
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Report">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>Report Details</h2>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Reported By:</Form.Label>
                        <Form.Control
                          type="text"
                          value={data?.data?.block_by?.full_name}
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Reported To:</Form.Label>
                        <Form.Control
                          type="text"
                          value={data?.data?.block_to?.full_name}
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Report Reason </Form.Label>
                        <Form.Control
                          type="text"
                          value={data?.data?.title}
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as="textarea"
                          value={data?.data?.description}
                          rows={3}
                          placeholder="Type Here"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>STATUS</h2>
                <Form.Select
                  onChange={(e) =>
                    dispatch(
                      changeReportStatus({
                        id: data?.data?._id,
                        status: e.target.value,
                      })
                    ).then((res) => {
                      console.log(res)
                      if (res?.payload?.success) {
                        setflag(!flag);
                      }
                    })
                  }
                  value={data?.data?.status}
                  aria-label="Default select example"
                >
                  <option value="0">Resolved</option>
                  <option value="1">Open</option>
                </Form.Select>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
