import { createSlice } from "@reduxjs/toolkit";
import { notifications } from "../Actions/Adminactions";
import { toast } from "react-toastify";

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState: {
    data: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notifications.fulfilled, (state, action) => {
      if(action?.payload?.success){
        state.data=action.payload
      }else{
      toast.error(action?.payload?.success)
      }
    });
  },
});

export default notificationSlice.reducer;
