import { createSlice } from "@reduxjs/toolkit";
import { profileshare } from "../Actions/Adminactions";
import { toast } from "react-toastify";


export const profileshareSlice=createSlice({
    name:"profileshareSlice",
    initialState:{
        profileData:""
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(profileshare.fulfilled,(state,action)=>{
            if(action?.payload?.success){
                state.profileData=action.payload
            }else{
                toast.error(action?.payload?.message)
            }
        })
    }
})

export default profileshareSlice.reducer