import React from "react";
import { Link } from "react-router-dom";

export default function PrivacyPolicySpanish() {
  return (
    <div>
      <div className="container">
        <div className="privacy_text">
          {/* <div className="header-logo">
    <Link className="terms-and-conditions link" to="#">
      <img
        className="terms-and-conditions-logo"
        src={require("product-hotspot/images/logo-1.0.svg")}
        alt="logo"
      />
    </Link>
  </div> */}
          <h3>Política de Privacidad</h3>
          <p>
            En URush, estamos comprometidos con la protección de la privacidad y
            seguridad de su información personal. Esta Política de Privacidad
            describe los tipos de información personal que recopilamos, cómo la
            usamos y las medidas que tomamos para protegerla.
          </p>

          <h3>1. Información que Recopilamos:</h3>
          <h4>a. Información Personal:</h4>
          <p>
            Cuando se registra en URush, podemos recopilar información personal
            como su nombre, dirección de correo electrónico, número de teléfono
            y detalles de la empresa. También podemos recopilar información
            adicional que decida proporcionar, como su cargo y preferencias.
          </p>
          <h4>b. Información de Uso:</h4>
          <p>
            Recopilamos automáticamente cierta información sobre su uso de
            URush, incluida su dirección IP, información del dispositivo, tipo
            de navegador y sistema operativo.
          </p>
          <h4>c. Cookies:</h4>
          <p>
            Utilizamos cookies y tecnologías de seguimiento similares para
            mejorar su experiencia y recopilar información sobre cómo interactúa
            con nuestra plataforma.
          </p>
          <h3>2. Cómo Usamos su Información:</h3>

          <h4>a. Provisión de Servicios:</h4>
          <p>
            Utilizamos su información personal para proporcionarle acceso a
            URush y sus características. Su información nos ayuda a personalizar
            y mejorar nuestros servicios para satisfacer mejor sus necesidades.
          </p>
          <h4>b. Comunicación:</h4>
          <p>
            Podemos utilizar su información de contacto para enviarle
            actualizaciones importantes, notificaciones y comunicaciones de
            marketing relacionadas con URush.
          </p>

          <h4>c. Análisis:</h4>
          <p>
            Analizamos patrones y tendencias de uso para mejorar el rendimiento
            y la funcionalidad de URush. Esto incluye monitorear la
            participación de los usuarios, solucionar problemas técnicos y
            realizar investigaciones.
          </p>
          <h3>3. Compartir su Información:</h3>

          <h4>a. Proveedores de Servicios de Terceros:</h4>
          <p>
            Podemos compartir su información con proveedores de servicios de
            terceros de confianza que nos ayudan a operar nuestra plataforma,
            llevar a cabo negocios y ofrecer servicios a usted.
          </p>

          <h4>b. Cumplimiento Legal:</h4>
          <p>
            Podemos divulgar su información para cumplir con las leyes
            aplicables, regulaciones, procesos legales o solicitudes
            gubernamentales exigibles.
          </p>
          <h3>4. Seguridad de los Datos:</h3>
          <p>
            a. Empleamos medidas de seguridad estándar de la industria para
            proteger su información personal contra el acceso no autorizado,
            alteración, divulgación o destrucción.
          </p>
          <p>
            b. A pesar de nuestros mejores esfuerzos, ningún método de
            transmisión por Internet o almacenamiento electrónico es
            completamente seguro. Por lo tanto, no podemos garantizar una
            seguridad absoluta.
          </p>

          <h3>5. Sus Opciones:</h3>
          <p>
            a. Usted tiene derecho a acceder, actualizar o eliminar su
            información personal en cualquier momento.
          </p>
          <p>
            b. Puede optar por no recibir comunicaciones de marketing de nuestra
            parte siguiendo las instrucciones proporcionadas en los mensajes.
          </p>
          <h3>6. Privacidad de los Niños:</h3>
          <p>
            URush no está destinado a ser utilizado por personas menores de 18
            años. No recopilamos intencionalmente información personal de niños.
          </p>
          <h3>7. Cambios en esta Política de Privacidad:</h3>
          <p>
            Podemos actualizar esta Política de Privacidad de vez en cuando. Le
            notificaremos cualquier cambio publicando la nueva Política de
            Privacidad en esta página.
          </p>
          <h3>8. Contáctenos:</h3>
          <p>
            Si tiene alguna pregunta o inquietud sobre esta Política de
            Privacidad o nuestras prácticas de datos, por favor contáctenos en{" "}
            <Link to="mailto:businessurush@gmail.com">
              businessurush@gmail.com
            </Link>
            .
          </p>

          <p>
            Al utilizar URush, usted acepta los términos descritos en esta
            Política de Privacidad.
          </p>
        </div>
      </div>
    </div>
  );
}
