import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";
import { Subscribed, userManagement } from "../Redux/Actions/Adminactions";
import { useDispatch } from "react-redux";
import Pagination from "../Components/Layout/Pagination";
import Logo from "../Assets/Images/Vector  6 .svg";
import * as XLSX from "xlsx";
import { dowanloadsubscribers } from "../Redux/Actions/Adminactions";

export default function SubscribedUsers() {
  const [data, setdata] = useState([]);
  console.log(data);

  const [pagination, setpagination] = useState(null);
  const [Subscribers, setSubscribers] = useState([]);
  const [limit, setlimit] = useState(10);
  // console.log(SubscribedUsers)

  const [userparms, setuserparms] = useState({
    page: 1,
    search: "",
    limit: "10",
    banStatus: 2,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchSubscribeddata = async () => {
      try {
        const data = await dispatch(Subscribed(userparms));
        console.log(data);

        setSubscribers(data?.payload?.data?.data?.subscribedUsers);
        setdata(data?.payload?.data?.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubscribeddata();
  }, [userparms]);

  const handlechange = async (e) => {
    try {
      const data = await dispatch(
        Subscribed({
          page: 1,
          search: "",
          limit: "10",
          banStatus: e.target.value,
        })
      );

      setSubscribers(data?.payload?.data?.data?.subscribedUsers);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDataAndExportToexcel = async () => {
    try {
      const response = await dispatch(dowanloadsubscribers());
      console.log(response);

      const allData = response?.payload?.data?.data;
      console.log(allData);

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch {}
  };

  useEffect(() => {
    setpagination(
      Math.ceil((userparms?.search ? 1 : data?.totalUsers) / data?.limit)
    );
  }, [data, userparms]);

  const handlePages = (page) => {
    setuserparms((old) => ({ ...old, page: page }));
  };

  const handlesearch = (e) => {
    setuserparms((old) => ({ ...old, search: e.target.value }));
  };

  const handlelimit = async (e) => {
    setlimit(e.target.value);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Subscribed users</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    value={userparms.search}
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                  onChange={handlechange}
                >
                  <option value="2">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Select>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToexcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">{" "}
                Showing {1 + (userparms?.page - 1) * limit} -{" "}
                {Subscribers?.length + (userparms?.page - 1) * limit} of{" "}
                {data?.totalUsers}{" "}</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={handlelimit}
                >
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Image</th>
                <th>Gender</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Subscribers?.map((x, index) => {
                return (
                  <tr>
                    <td key={index}>{index + 1}</td>
                    <td>{x?.full_name}</td>
                    <td>{x?.email}</td>

                    <td className="user-img">
                      <img
                        src={
                          x?.profile_image
                            ? `https://urush-api.bosselt.com/${x?.profile_image}`
                            : Logo
                        }
                      />
                    </td>
                    <td>{x?.gender}</td>
                    <td className="user-img">
                      {x?.banStatus == 1 ? "Active" : "Inactive"}
                    </td>
                  </tr>
                );
              })}
              {/* <tr>
                <td>1</td>
                <td>James</td>
                <td>abc@domain.com</td>

                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Male</td>
                <td className="user-img">Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>abc@domain.com</td>

                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Male</td>
                <td className="user-img">Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>abc@domain.com</td>

                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Male</td>
                <td className="user-img">Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>abc@domain.com</td>

                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Male</td>
                <td className="user-img">Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>abc@domain.com</td>

                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Male</td>
                <td className="user-img">Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>James</td>
                <td>abc@domain.com</td>

                <td className="user-img">
                  <img src={require("../Assets/Images/user.png")} />
                </td>
                <td>Male</td>
                <td className="user-img">Active</td>
              </tr> */}
            </tbody>
          </Table>
        </div>
      </Container>

      {/* <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div> */}

      <Pagination
        totalstuff={data?.totalUsers}
        limit={data?.limit}
        setpages={userparms?.page}
        search={userparms?.search}
      />
    </Layout>
  );
}
