import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../Apiconfig/Apiconfig";
import { toast } from "react-toastify";

const login = createAsyncThunk("Login", async (details) => {
  const { data } = await Api.post("/adminLogin", details);

  if (data?.success) {
    sessionStorage.setItem("token", data?.data?.token);
  }

  return data;
});

export { login };
