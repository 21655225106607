import React from "react";
import { Area, AreaChart, Tooltip, XAxis, YAxis } from "recharts";
// import { AreaChart, Area, XAxis, YAxis, Tooltip } from ;
export default function CityChart() {
  const data = [
    {
      name: "UCSG",
      uv: 500,
    },
    {
      name: "ECOTEC",
      uv: 1000,
    },
    {
      name: "UEES",
      uv: 300,
    },
    {
      name: "UIDE",
      uv: 300,
    },
  ];
  return (
    <div className="user-graph">
      <AreaChart
        width={750}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uv"
          stackId="1"
          stroke="#E03D84"
          fill="#6916AE"
        />
      </AreaChart>
    </div>
  );
}
