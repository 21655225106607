import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteNotification,
  notifications,
} from "../Redux/Actions/Adminactions";
import Pagination from "../Components/Layout/Pagination";

export default function Notification() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notificationData.data);
  console.log(data);
  const [pages, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [show, setshow] = useState(false);
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(notifications({ page: pages, limit: limit, search: search }));
  }, [flag, pages, limit, search]);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => setsearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/AddNotification">Create New</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Notification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.data?.notifications?.length + (pages - 1) * limit} of{" "}
                {data?.data?.totalUsers}{" "}
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  name="limit"
                  onChange={(e)=>setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>TITLE</th>
                <th>CREATED DATE</th>
                <th>DESCRIPTION</th>
                <th>SENT TO</th>
                <th>ACTION </th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.notifications?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{x?.notification_title || "N/A"}</td>
                    <td>
                      {new Date(x?.createdAt).toLocaleDateString() || "N/A"}
                    </td>
                    <td>{x?.notification_description || "N/A"}</td>
                    <td>{x?.to === 2 ? "All Users" : x?.to === 0 ? "All Male" : "All Female"}</td>
                    <td>
                      <Button
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setId(x?._id);
                          setshow(true);
                        }}
                        className="account-btn common-colr-btn"
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.notifications?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      {/* <Modal show={show} animation={false}>
        <Modal.Body>
          <p className="modal_head ">
            Are you sure you want to Delete this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button
            variant="primary"
            onClick={() =>
              dispatch(deleteNotification({ id: id })).then((res) => {
                if (res?.payload?.success) {
                  setflag(!flag);
                  setshow(false);
                }
              })
            }
          >
            Yes
          </Button>
          <Button variant="primary" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account ?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn"  onClick={() =>
              dispatch(deleteNotification({ id: id })).then((res) => {
                if (res?.payload?.success) {
                  setflag(!flag);
                  setshow(false);
                }
              })
            }>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Pagination
        totalstuff={data?.data?.totalUsers}
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />
    </Layout>
  );
}
