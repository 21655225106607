import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../Apiconfig/Apiconfig";
import { toast } from "react-toastify";

// changeadminPass
export const changepassword = createAsyncThunk(
  "changepassword",
  async (details) => {
    console.log(details);
    const  {data}  = await Api.post("/changePassAdmin", details);
    console.log(details);
    return data;
  }
);

//get all users

export const userManagement = createAsyncThunk(
  "UserManagement",
  async (details) => {
    const { data } = await Api.get(
      `/getAllUsers?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
    );
    
    return data;
  }
);

export const alldatauserManagement = createAsyncThunk(
  "alldatauserManagement",
  async () => {
    const { data } = await Api.get(`/getUsers`);

    return data;
  }
);

// logout admin

export const logout = createAsyncThunk("adminlogout", async () => {
  const { data } = await Api.post("/logOut");
  return data;
});

// get user profile

export const getuserprofile = createAsyncThunk("getuserprofile", async (id) => {
  const { data } = await Api.get(`/getUserProfile?id=${id?.id}`);

  return data;
});

// delete Asccount

export const deleteaccount = createAsyncThunk(
  "deleteuseraccount",
  async (id) => {
    const data = await Api.post("/deleteUserAccount", id);

    return data;
  }
);

// Banned

export const ActivateDeactivate = createAsyncThunk(
  "ActivateDeactivate",
  async (details) => {
    const data = await Api.post("/tempBanOrUnbanAccount", details);

    return data;
  }
);

// Activity

export const AdminActivity = createAsyncThunk(
  "AdminActivity",
  async (details) => {
    const { data } = await Api.get(`/getUserActivity?user_id=${details}`);

    return data;
  }
);

// Forget Password

export const forgetPassword = createAsyncThunk(
  "forgetPassword",
  async (details) => {
    const { data } = await Api.post(`/forgotPassword`, details);

    return data;
  }
);

// OTP verification

export const otpVerification = createAsyncThunk(
  "otpVerification",
  async (details) => {
    const { data } = await Api.post(`/verifyOtp`, details);

    return data;
  }
);

// Reset Password

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (details) => {
    const { data } = await Api.post(`/resetPassword`, details);

    return data;
  }
);

// Active ban listing

export const ActiveListing = createAsyncThunk(
  "ActiveListing",
  async (details) => {
    const { data } = await Api.get(
      `/banUnbanUsers?page=${details?.page}&limit=${details?.limit}&search=${details.search}&banStatus=${details.banStatus}`
    );

    return data;
  }
);
export const AllActiveListing = createAsyncThunk(
  "ActiAllActiveListingveListing",
  async (details) => {
    const { data } = await Api.get(
      `/banUnbanUsers?banStatus=${details?.banStatus}`
    );

    return data;
  }
);

// Dashboard data

export const Dashboarddata = createAsyncThunk(
  "Dashboarddata",
  async (details) => {
    const { data } = await Api.get(`/dashboard`);

    return data;
  }
);

// add notification

export const addNotifications = createAsyncThunk(
  "addNotifications",
  async (detail) => {
    const { data } = await Api.post(`/addNotification  `, detail);

    return data;
  }
);

// Listing notification

export const notifications = createAsyncThunk(
  "notifications",
  async (details) => {
    const { data } = await Api.get(
      `/getAllNotifications?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
    );

    return data;
  }
);

// Delete Notification

export const deleteNotification = createAsyncThunk(
  "deleteNotification",
  async (detail) => {
    const { data } = await Api.post(`/deleteNotification`, detail);
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

// get reports

export const getReports = createAsyncThunk("getReports", async (details) => {
  const { data } = await Api.get(
    `/getReports?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
  );

  return data;
});

// getAllReports

export const getAllReports = createAsyncThunk(
  "getAllReports",
  async (details) => {
    const { data } = await Api.get(`/getAllReports`);

    return data;
  }
);

// report details

export const getreportdetails = createAsyncThunk(
  "getreportdetails",
  async (id) => {
    const { data } = await Api.get(`/getReportById?id=${id}`);

    return data;
  }
);

// close report

export const changeReportStatus = createAsyncThunk(
  "changeReportStatus",
  async (detail) => {
    const { data } = await Api.post(`/closeReport`, detail);
    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

// get events

export const getEvents = createAsyncThunk("getEvents", async (details) => {
  const { data } = await Api.get(
    `/getEvents?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
  );

  return data;
});

// delete event

export const deleteEvent = createAsyncThunk("deleteEvent", async (detail) => {
  const { data } = await Api.post(`/deleteEvent`, detail);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }
  return data;
});

// add event

export const addEvent = createAsyncThunk("addEvent", async (detail) => {
  const { data } = await Api.post(`/addEvent`, detail);
  console.log(data);
  if (data?.success) {
    toast.success(data?.message);
  } else {
    toast.error(data?.message);
  }
  return data;
});

// Event by id

export const eventById = createAsyncThunk("eventById", async (details) => {
  const { data } = await Api.get(`/getEventById?id=${details}`);

  return data;
});

// event share

export const eventshare = createAsyncThunk("eventshare", async (details) => {
  const { data } = await Api.get(`/getEventBywithOutTokenId?id=${details}`);

  return data;
});

// share profile

export const profileshare = createAsyncThunk(
  "profileshare",
  async (details) => {
    const { data } = await Api.get(`/getUserWithOutTokenId?id=${details}`);

    return data;
  }
);

// support Data

export const supportData = createAsyncThunk("supportData", async (details) => {
  const { data } = await Api.get(
    `/getSupports?page=${details?.page}&limit=${details?.limit}&search=${details.search} `
  );

  return data;
});

// change Support Status

export const changesupportStatus = createAsyncThunk(
  "changesupportStatus",
  async (detail) => {
    const { data } = await Api.post(`/changeSupportStatus`, detail);

    if (data?.success) {
      toast.success(data?.message);
    } else {
      toast.error(data?.message);
    }
    return data;
  }
);

// get supportchAT

export const getSupportChat = createAsyncThunk(
  "getSupportChat",
  async (details) => {
    const { data } = await Api.get(`/getSupportChat?id=${details}`);

    return data;
  }
);

// message from admin

export const adminchatmessages = createAsyncThunk(
  "adminchatmessages",
  async (details) => {
    const { data } = await Api.post(`/sendSupportMessage`, details);
    return data;
  }
);

// Subscribed Users

export const Subscribed = createAsyncThunk(
  "SubscribedUsers",
  async (details) => {
    const data = await Api.get(
      `/getAllSubscribedUsers?page=${details?.page}&limit=${details?.limit}&search=${details.search}&banStatus=${details?.banStatus}`
    );
    return data;
  }
);

export const dowanloadsubscribers = createAsyncThunk(
  "dowanloadsubscribers",
  async () => {
    const data = await Api.get("getSubscribedUsers");

    return data;
  }
);


// get all Ads

export const getAdvertisment = createAsyncThunk(
  "getAdvertisment",
  async (details) => {
    const data = await Api.get(
      `/getAllAds?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
    );
    return data;
  }
);

 // delete adds

export const deleteAds = createAsyncThunk(
  "deleteAds",
  async (details) => {
    const data = await Api.post(
      `/DeleteAds`, details
    );
    console.log(data,"manageadssssssssssss");
    return data;
  }
);

// create adds

export const createAdds = createAsyncThunk(
  "createAdds",
  async (details) => {
    const data = await Api.post(
      `/createAds`, details
    );
    return data;
  }
);

export const SubmitDeleteRequest = createAsyncThunk(
  "SubmitDeleteRequest",
  async (details) => {
    const data = await Api.post(
      `/submitDeleteRequest`, details
    );
    return data;
  }
);

export const GetDeleteRequest = createAsyncThunk(
  "GetDeleteRequest",
  async (details) => {
    const data = await Api.get(
      `/getDeleteRequests?page=${details?.page}&limit=${details?.limit}&search=${details.search}`
    );
    return data;
  }
);

// contact ue

export const contactUs  = createAsyncThunk("contactUs ", async (details) => {
  const { data } = await Api.post(
    `/contactUs `,details
  );

  return data;
});







