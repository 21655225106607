import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getAllReports, getReports, getuserprofile } from "../Redux/Actions/Adminactions";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { Modal,Button } from "react-bootstrap";


export default function Report() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.reportsData.data);
  console.log(data);
  const navigate = useNavigate();
  const [pages, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [show, setshow] = useState(false);
  const [ID, setID] = useState("");
  const [Count, setCount] = useState("");
  console.log(Count,"</h4> </h4> </h4> </h4>")

  useEffect(() => {
    dispatch(getReports({ page: pages, limit: limit, search: search }));
  }, [flag, pages, limit, search]);

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(getAllReports());
      console.log(response);

      const allData = response?.payload?.data
      console.log(allData)

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
      
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };


  useEffect(() => {
    if (ID) {
      const getdata = async () => {
        try {
          const data = await dispatch(getuserprofile({ id: ID }));
          console.log(data); 
          if(data?.payload?.status_code === 200){
            setCount(data?.payload?.message);
          }
        } catch (error) {
          console.error('Error fetching user profile:', error);
        }
      };
      getdata();
    }
  }, [ID, dispatch]);

  const handleClick = (Id) => {
    setshow(true); 
    setID(Id); 
  };


  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Report</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => setsearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {data?.data?.reports?.length + (pages - 1) * limit} of{" "}
                {data?.data?.totalUser}{" "}
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Date</th>
                <th>Reported by</th>
                <th>Reported to</th>
                <th style={{ marginRight:"5px" }}>Block Count</th>
                <th>Reason</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.reports?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{new Date(x?.createdAt).toLocaleDateString()}</td>
                    <td
                      style={{ cursor: "pointer",}}
                      onClick={() =>
                        navigate("/ReportDetails", {
                          state: {
                            id: x?._id,
                          },
                        })
                      }
                    >
                      <b>{x?.block_by?.full_name}</b>
                    </td>
                    <td>{x?.block_to?.full_name}</td>
                    <div className="cmn-btn text-center"  onClick={() =>  handleClick(x?.block_to?._id) }>
                  <Link style={{ width:"20px"  }}>Count</Link>
                </div>
                    <td>{x?.title}</td>
                    <td>{x?.description}</td>
                    <td>{x?.status == "1" ? "Opened" : "Resolved"}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {data?.data?.reports?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.data?.totalUser}
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />


<Modal show={show} animation={false} className="report-popup" centered>
      <Modal.Header closeButton onHide={() => setshow(false)}>
        <Modal.Title className="w-100 text-center">
          {/* Optional Title */}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center modal-body">
        <h4 className="are-sure-text pt-2">
          Block Count: {Count?.blocks_count}
        </h4>
      </Modal.Body>
    </Modal>


    </Layout>
  );
}
