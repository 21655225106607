import { createSlice } from "@reduxjs/toolkit";
import { getReports, getreportdetails, notifications } from "../Actions/Adminactions";
import { toast } from "react-toastify";

export const reportsSlice = createSlice({
  name: "reportsSlice",
  initialState: {
    data: "",
    reportbyid:""
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReports.fulfilled, (state, action) => {
      if(action?.payload?.success){
        state.data=action.payload
      }else{
      toast.error(action?.payload?.success)
      }
    });
    builder.addCase(getreportdetails.fulfilled, (state, action) => {
      if(action?.payload?.success){
        state.reportbyid=action.payload
      }else{
      toast.error(action?.payload?.success)
      }
    });
  },
});

export default reportsSlice.reducer;