import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { forgetPassword } from "../Redux/Actions/Adminactions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

export default function ForgotPassword() {
  const dispatch=useDispatch()
  const navigate=useNavigate()
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/logo-name.png")}/>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Forgot Password</h2>

                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                   
                    dispatch(forgetPassword(values)).then((res)=>{
                      if(res?.payload?.success){
                        toast.success(res?.payload?.message)
                        navigate("/Otp",{state:{
                          adminId:res?.payload?.data?._id
                        }})
                      }else{
                        toast.error(res?.payload?.message)
                      }
                    })
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form>
                      <div className="form-set">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>

                          <Field
                            type="email"
                            name="email"
                            placeholder="Email"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.email}
                          />
                          <ErrorMessage className="text-danger" name="email" component="div"/>
                        </Form.Group>
                      </div>
                      <div onClick={handleSubmit}>
                      <Link
                        // to="/otp"
                        
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                        Send OTP
                      </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
