import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { contactUs } from "../Redux/Actions/Adminactions";

function ContactUs() {

  const dispatch=useDispatch()
  const validateYupSchema = yup.object().shape({
    name: yup.string().required(<span style={{ color: "red" }}>Required</span>),
    email: yup
      .string()
      .email(<span style={{ color: "red" }}>Invalid Email Address</span>)
      .required(<span style={{ color: "red" }}>Required</span>),
    message: yup
      .string()
      .required(<span style={{ color: "red" }}>Required</span>),
  });

  return (
    <>
      <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="logo">
                <img src={require("../Assets/Images/logo-name.png")} />
              </div>
            </Col>
          </Row>
        </Container>
      <section
        style={{ alignItems: "center" }}
        className="contact_page_section mb-4"
        id="contact"
      >
        <Container>
          <div className="contact_inner">
            <div className="contact_form">
              <div className="section_title">
                <h2>Contact Us</h2>
              </div>
              <Formik
                initialValues={{ name: "", email: "", message: "" }}
                validationSchema={validateYupSchema}
                onSubmit={async (values, { resetForm }) => {
                  console.log(values);
                  try{
                    const data = await dispatch(contactUs(values));
                    if(data?.payload?.success){
                      toast.success(data?.payload?.message);
                       resetForm();
                    }
                    console.log(data);
                  }catch(error){
                    console.log(error);
                  }
                }}
              >
                {({ values, handleChange, handleSubmit }) => (
                  <Form id="contactForm">
                    <div className="form-group">
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        className="form-control"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.name}
                      />
                      <ErrorMessage name="name" component="div"/>
                    </div>
                    <div className="form-group">
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        className="form-control"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.email}
                      />
                      <ErrorMessage name="email" component="div"/>
                    </div>
                    <div className="form-group">
                      <Form.Control
                        as="textarea"
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        onChange={handleChange}
                        value={values.message}
                      ></Form.Control>
                      <ErrorMessage name="message" component="div"/>
                    </div>
                    

                    <div variant="primary" className="form-group mb-0">
                      <button
                        className="submit"
                        type="button"
                        onClick={handleSubmit}
                      >
                        SEND MESSAGE
                      </button>
                    </div>

                    {/* <div
                      style={{ cursor: "pointer" }}
                      // to="/Dashboard"
                      variant="primary"
                      // type="submit"
                    //   onClick={handlesubmit}
                      className="submit"
                    >
                      Log In
                    </div> */}
                  </Form>
                )}
              </Formik>
            </div>

            {/* <div className="contact_info">
              <div className="section_title">
                <h2 className="mb-4">Want To Reach Out?</h2>
              </div>
              <ul className="contact_info_list">
                <li>
                  <div className="img">
                    <img
                      src={require("../images/location_icon.svg").default}
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Company Location</span>
                    <p>Sweden</p>
                  </div>
                </li>
                <li>
                  <div className="img">
                    <img
                      src={require("../images/message_icon.svg").default}
                      alt="image"
                    />
                  </div>
                  <div className="text">
                    <span>Email Address</span>
                    <Link to="mailto:wecare@telepharmacare.com">
                      wecare@telepharmacare.com
                    </Link>
                  </div>
                </li>
              </ul>
            </div> */}
          </div>
        </Container>
      </section>
    </>
  );
}

export default ContactUs;
