import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend);



export default function Orders() {
  const dashboardData = useSelector((state) => state.dashdata.dashboarddata);
  console.log(dashboardData)


   const data = {
    labels:  ["Men ", "Women ", "Others "],
    datasets: [
      {
        label: "# of Votes",
        data: [ dashboardData?.data?.totalMens, dashboardData?.data?.totalWomens, dashboardData?.data?.others],
        backgroundColor: ["#6916AE", "#E03D84", "#d9c4ea"],
  
        borderWidth: 1,
      },
    ],
  };
  return( 
    
 
      <Doughnut className="gender-chart" data={data} />
 )

}
