import { createSlice } from "@reduxjs/toolkit";
// import { supportData } from "../actions/adminactions";
import { toast } from "react-toastify";
import { getSupportChat, supportData } from "../Actions/Adminactions";




export const supportSlice=createSlice({
    name:"supportSlice",
    initialState:{
        supportList:"",
        supportMessage:""
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(supportData.fulfilled,(state,action)=>{
            if(action?.payload?.success){
                state.supportList=action.payload
            }else{
                toast.error(action?.payload?.message)
            }
        });
        builder.addCase(getSupportChat.fulfilled,(state,action)=>{
            if(action?.payload?.success){
                state.supportMessage=action.payload
            }else{
                toast.error(action?.payload?.message)
            }
        })
    }
    
})