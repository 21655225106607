import { createSlice } from "@reduxjs/toolkit";
import {
  ActiveListing,
  AdminActivity,
  getuserprofile,
  userManagement,
} from "../Actions/Adminactions";

export const usermgmtSlice = createSlice({
  name: "usermgmtSlice",
  initialState: {
    allData: null,
    profileData: null,
    ActivityData: null,
    ActiveData: null,
    AllPgNo: null,
    ActivePgNo: null,
    SuspendPgNo: null,
    allPagePath: null,
    activePagePath: null,
    suspendPagePath: null,
  },
  reducers: {
    AllPageNumber: (state, action) => {
      state.AllPgNo = action.payload;
    },
    ActivePageNumber: (state, action) => {
      state.ActivePgNo = action.payload;
    },
    SuspendPageNumber: (state, action) => {
      state.SuspendPgNo = action.payload;
    },
    PathAllPage: (state, action) => {
      state.allPagePath = action.payload;
    },
    ActivePage: (state, action) => {
      state.activePagePath = action.payload;
    },
    SuspendPath: (state, action) => {
      state.suspendPagePath = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(userManagement.fulfilled, (state, action) => {
      state.allData = action.payload;
    });

    builder.addCase(getuserprofile.fulfilled, (state, action) => {
      state.profileData = action.payload;
    });

    builder.addCase(AdminActivity.fulfilled, (state, action) => {
      state.ActivityData = action.payload;
    });
    builder.addCase(ActiveListing.fulfilled, (state, action) => {
      state.ActiveData = action.payload;
    });
  },
});

export const {
  AllPageNumber,
  PathAllPage,
  ActivePage,
  ActivePageNumber,
  SuspendPath,
  SuspendPageNumber,
} = usermgmtSlice.actions;

export default usermgmtSlice.reducer;
