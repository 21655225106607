import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";

import { useDispatch, useSelector } from "react-redux";
import {
  ActivateDeactivate,
  ActiveListing,
  AllActiveListing,
} from "../Redux/Actions/Adminactions";
import Logo from "../Assets/Images/Vector  6 .svg";
import Pagination from "../Components/Layout/Pagination";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import {
  SuspendPageNumber,
  SuspendPath,
} from "../Redux/Reducers/usermgmtSlice";
export default function Suspended() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const data = useSelector((state) => state.usersdata.ActiveData);
  const pathfromRedux = useSelector((state) => state.usersdata.suspendPagePath);
  const pagefromRedux = useSelector((state) => state.usersdata.SuspendPgNo);
  console.log(data);

  const [limit, setlimit] = useState(10);
  const [range, setRange] = useState(true);
  const [search, setsearch] = useState("");
  const [flag, setflag] = useState(true);
  const [pages, setpages] = useState(pagefromRedux ? pagefromRedux : 1);
  const [filterDataByDate, setFilterDataByDate] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [pickerVisible, setPickerVisible] = useState(false);

  useEffect(() => {
    dispatch(
      ActiveListing({
        page: search ? 1 : pages,
        limit: limit,
        search: search,
        banStatus: 0,
      })
    );
  }, [search, limit, pages, flag]);

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const handleactive = (id, e) => {
    dispatch(
      ActivateDeactivate({
        id: id,
        action: e.target.value,
      })
    ).then((res) => {
      if (res?.payload?.data?.success) {
        toast.success(res?.payload?.data?.message);
        setflag(!flag);
      } else {
        toast.error(res?.payload?.data?.message);
      }
    });
  };

  const handleuprofile = (user) => {
    navigate("/CustomerProfile", {
      state: {
        id: user,
        path: pathfromRedux,
      },
    });
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(AllActiveListing({ banStatus: 0 }));
      console.log(response);

      const allData = response?.payload?.data?.banUsers;

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  useEffect(() => {
    dispatch(SuspendPageNumber(pages));
  }, [pages]);

  //Date Picker

  // const startformattedDate = moment(dateRange[0]?.startDate)?.format(
  //   "YYYY-MM-DD"
  // );
  // const endformattedDate = moment(dateRange[0]?.endDate)?.format("YYYY-MM-DD");

  // useEffect(() => {
  //   let fetchdata = async () => {
  //     if (!initialRender) {
  //       const filterdata = data?.data?.banUsers?.filter((x) => {
  //         return (
  //           x?.createdAt?.slice(0, 10) >= startformattedDate &&
  //           x?.createdAt?.slice(0, 10) <= endformattedDate
  //         );
  //       });

  //       setFilterDataByDate(filterdata);
  //     }
  //     setInitialRender(false);
  //   };
  //   fetchdata();
  // }, [range]);

  // const getStartDateText = () => {
  //   return dateRange[0]?.startDate
  //     ? dateRange[0]?.startDate?.toLocaleDateString()
  //     : "";
  // };

  // const handleDateFieldClick = () => {
  //   setPickerVisible(true);
  // };

  // const handleClose = () => {
  //   setPickerVisible(false);

  //   setflag(!flag);
  //   setDateRange([
  //     {
  //       startDate: null,
  //       endDate: null,
  //       key: "selection",
  //     },
  //   ]);
  //   setFilterDataByDate(data);
  // };

  // const handleSelect = (ranges) => {
  //   setDateRange([ranges?.selection]);
  // };

  // today

  // const todayrange = () => {
  //   const currentDate = new Date();

  //   setDateRange([
  //     {
  //       startDate: currentDate,
  //       endDate: currentDate,
  //       key: "selection",
  //     },
  //   ]);
  //   setRange(!range);
  //   setPickerVisible(false);
  // };

  // yesterday

  // const yesterdayrange = () => {
  //   const currentDate = new Date();
  //   const yesterday = new Date(currentDate);
  //   yesterday.setDate(currentDate.getDate() - 1);

  //   setDateRange([
  //     {
  //       startDate: yesterday,
  //       endDate: yesterday,
  //       key: "selection",
  //     },
  //   ]);
  //   setRange(!range);
  //   setPickerVisible(false);
  // };

  // 7 days before

  // const sevenDaysBefore = () => {
  //   const currentDate = new Date();
  //   const sevendays = new Date(currentDate);
  //   sevendays.setDate(currentDate.getDate() - 7);

  //   setDateRange([
  //     {
  //       startDate: sevendays,
  //       endDate: currentDate,
  //       key: "selection",
  //     },
  //   ]);
  //   setRange(!range);
  //   setPickerVisible(false);
  // };

  //close range

  // const closerange = () => {
  //   setPickerVisible(false);
  //   setRange(!range);
  // };

  // path from redux

  useEffect(() => {
    dispatch(SuspendPath(location?.pathname));
  }, [location?.pathname]);

  console.log(filterDataByDate);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Suspended</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    name="search"
                    onChange={handlesearch}
                    type="search"
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="date" placeholder="Keyword Search.." />
                </Form.Group>
                <input
                  type="text"
                  className="form-control"
                  value={`${getStartDateText()}     ${
                    dateRange[0]?.endDate?.toLocaleDateString() ||
                    "Select date range"
                  }`}
                  onClick={handleDateFieldClick}
                  readOnly
                />

                {pickerVisible && (
                  <div
                    className="date-picker"
                    style={{
                      width: "500px",
                      height: "500px",
                      overflow: "scroll",
                      position: "absolute",
                    }}
                  >
                    <div className="d-flex gap-3 justify-content-end m-2 date-range-filters">
                      <Button onClick={handleClose}>Clear</Button>
                    </div>
                    <DateRange
                      ranges={dateRange}
                      onChange={handleSelect}
                      months={2}
                      direction="horizontal"
                    />
                    <div className="d-flex gap-3 justify-content-between date-range-filters">
                      <div className="d-flex gap-3">
                        <Button onClick={todayrange}>Today</Button>
                        <Button onClick={yesterdayrange}>Yesterday</Button>
                        <Button onClick={sevenDaysBefore}>before 7 days</Button>
                      </div>
                      <div>
                        <Button onClick={closerange}>Ok</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Col> */}

              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">ALL</Link>
              </li>
              <li>
                <Link to="/UserActive">Active</Link>
              </li>
              <li>
                <Link to="/Suspended" className="active-tab">
                  Suspended
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                Showing {1 + (pages - 1) * limit} -{" "}
                {/* {filterDataByDate?.length >= 0
                  ? filterDataByDate?.length
                  : */}
                  { data?.data?.banUsers?.length + (pages - 1) * limit}{" "}
                of{" "}
                {/* {filterDataByDate?.length >= 0
                  ? filterDataByDate?.length
                  : */}
                  { data?.data?.totalUsers}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  name="limit"
                  onChange={handlelimit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Joined Dated</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Profile Image</th>
                <th>Location</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              
                {/* (filterDataByDate?.length >= 0
                ? filterDataByDate
                : */}
                 {data?.data?.banUsers
              
              ?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;

                return (
                  <tr key={index}>
                    <td>{serialNumber}</td>

                    <td>
                      {/* <Link to="/CustomerProfile">{x?.full_name}</Link> */}

                      <p
                        className="link-tag"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleuprofile(x?._id)}
                      >
                        {x?.full_name || "N/A"}
                      </p>
                    </td>
                    <td>{x?.email || "N/A"}</td>
                    <td className="user-img">
                      <img
                        src={
                          x?.profile_image
                            ? `https://urush-api.bosselt.com/${x?.profile_image}`
                            : Logo
                        }
                      />
                    </td>
                    <td>{x?.address || "N/A"}</td>
                    <td>{x?.createdAt?.slice(0, 10) || "N/A"}</td>
                    <td>
                      {x?.status === 0
                        ? "In-active"
                        : x.banStatus === 1
                        ? "Active"
                        : "Suspend"}
                    </td>
                    <td className="select-box">
                      <Form.Select
                        value={x?.banStatus === 0 ? "ban" : "active"}
                        onChange={(e) => handleactive(x?._id, e)}
                        aria-label="Default select example"
                      >
                        <option value="ban">Suspend</option>
                        <option value="active">Active</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
             
            </tbody>
          </Table>
          {/* {filterDataByDate?.length == 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No data available yet</p>
                </div>
              )} */}
              {data?.data?.banUsers?.length == 0 && (
                <div className="no-data-updated">
                  <p className="no-data">No data available yet</p>
                </div>
              )}
        </div>
      </Container>

      <Pagination
        totalstuff={
          // filterDataByDate?.length >= 0
          //   ? filterDataByDate?.length
          //   : 
            data?.data?.totalUsers
        }
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />
    </Layout>
  );
}
