import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
import Suspended from "./pages/Suspended";
import UserActive from "./pages/UserActive";
import CustomerProfile from "./pages/CustomerProfile";
import AddServices from "./pages/AddServices";
import SubscribedUsers from "./pages/SubscribedUsers";
import Notification from "./pages/Notification";
import AddNotification from "./pages/AddNotification";
import ChangePassword from "./pages/ChangePassword";
import CreateMail from "./pages/CreateMail";
import Report from "./pages/Report";
import Events from "./pages/Events";
import ReportDetails from "./pages/ReportDetails";
import EventDetails from "./pages/EventDetails";
import ForgotPasswordTwo from "./pages/ForgotPasswordtwo";
import ProtectedRoutes from "./Components/ProtectedRoutes";
import ManageAdvertisment from "./pages/ManageAdvertisment";
import CreateAdvertisment from "./pages/CreateAdvertisment";
import FeedDetails from "./pages/FeedDetails";
import UserProfileAdmin from "./pages/UserProfileAdmin";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccountweb from "./pages/DeleteAccountweb";
import DeleteAccountRequest from "./pages/DeleteAccountRequest";
import TermsAndConditionsSpanish from "./pages/TermsAndConditionsSpanish";
import PrivacyPolicySpanish from "./pages/PrivacyPolicySpanish";
import ContactUs from "./pages/ContactUs";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="Otp" element={<LoginOtp />} />
            <Route path="Forgot-Password" element={<ForgotPassword />} />
            <Route path="change-password" element={<ForgotPasswordTwo />} />
            <Route path="UserManagement" element={<UserManagement />} />
            <Route path="Support" element={<Support />} />
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="EventDetails" element={<EventDetails />} />
            <Route path="ReportDetails" element={<ReportDetails />} />
            <Route path="Events" element={<Events />} />
            <Route path="CreateMail" element={<CreateMail />} />
            <Route path="Report" element={<Report />} />
            <Route path="CustomerProfile" element={<CustomerProfile />} />
            <Route path="Supportchat/:id" element={<Supportchat />} />
            <Route path="Suspended" element={<Suspended />} />
            <Route path="UserActive" element={<UserActive />} />
            <Route path="AddServices" element={<AddServices />} />
            <Route path="SubscribedUsers" element={<SubscribedUsers />} />
            <Route path="Notification" element={<Notification />} />
            <Route path="AddNotification" element={<AddNotification />} />
            <Route path="ChangePassword" element={<ChangePassword />} />
            <Route path="ManageAdvertisment" element={<ManageAdvertisment />} />
            <Route path="CreateAdvertisment" element={<CreateAdvertisment />} />
          </Route>
          <Route path="FeedDetails/:id" element={<FeedDetails />} />
          <Route path="UserProfileAdmin/:id" element={<UserProfileAdmin />} />
          <Route path="TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/Contact-us" element={<ContactUs />} />
          <Route path="delete-account" element={<DeleteAccountweb />} />
          <Route
            path="delete-account-request"
            element={<DeleteAccountRequest />}
          />
          <Route
            path="terms-conditions-spanish"
            element={<TermsAndConditionsSpanish />}
          />
          <Route
            path="privacy-policy-spanish"
            element={<PrivacyPolicySpanish />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
