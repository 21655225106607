import React from "react";
import { Link } from "react-router-dom";

export default function TermsAndConditions() {
  return (
    <div>
      <div className="container">
        <div className="privacy_text">
          {/* <div className="header-logo">
            <Link className="terms-and-conditions link" to="#">
              <img
                className="terms-and-conditions-logo"
                src={require("product-hotspot/images/logo-1.0.svg")}
                alt="logo"
              />
            </Link>
          </div> */}
          <h3>Terms & Conditions </h3>
          <p>
            Welcome to URush! These Terms & Conditions govern your use of
            URush's platform and services. By accessing or using URush, you
            agree to abide by these Terms & Conditions.
          </p>
          <h4>1. Account Registration</h4>
          <p>
            a. To access certain features of URush, you may be required to
            register for an account.
          </p>
          <p>
            b. You agree to provide accurate, current, and complete information
            during the registration process.
          </p>
          <p>
            c. You are responsible for maintaining the confidentiality of your
            account credentials and for all activities that occur under your
            account.
          </p>

          <h4>2. Acceptable Use:</h4>
          <p>
            a. You agree to use URush solely for lawful purposes and in
            accordance with these Terms & Conditions.
          </p>
          <p>
            b. You shall not engage in any activity that interferes with or
            disrupts the operation of URush or its associated services.
          </p>
          <p>
            c. You shall not use URush to transmit any content that is unlawful,
            defamatory, obscene, offensive, or infringes upon the rights of
            others.
          </p>

          <h4>3. Intellectual Property:</h4>
          <p>
            a. URush and its content, including but not limited to text,
            graphics, logos, images, and software, are protected by copyright,
            trademark, and other intellectual property laws.
          </p>
          <p>
            b. You agree not to modify, reproduce, distribute, or create
            derivative works based on URush's content without prior written
            consent from URush.
          </p>
          <h4>4. Privacy:</h4>

          <p>
            a. Your privacy is important to us. Please refer to our Privacy
            Policy for information on how we collect, use, and disclose your
            personal information.
          </p>
          <p>
            b. By using URush, you consent to the collection and use of your
            information as described in our Privacy Policy.
          </p>

          <h4>5. Third-Party Links:</h4>
          <p>
            a. URush may contain links to third-party websites or services that
            are not owned or controlled by URush.
          </p>
          <p>
            b. URush has no control over, and assumes no responsibility for, the
            content, privacy policies, or practices of any third-party websites
            or services.
          </p>
          <p>
            c. You access third-party websites or services at your own risk and
            should review the applicable terms and policies.
          </p>
          <h4>6. Limitation of Liability:</h4>
          <p>
            a. To the fullest extent permitted by law, URush and its affiliates,
            officers, directors, employees, and agents shall not be liable for
            any direct, indirect, incidental, special, or consequential damages
            arising out of or in any way connected with your use of URush.
          </p>
          <p>
            b. URush's total liability, in any circumstance, shall be limited to
            the amount paid by you, if any, for accessing URush.
          </p>
          <h4>7. Indemnification:</h4>
          <p>
            You agree to indemnify and hold URush and its affiliates, officers,
            directors, employees, and agents harmless from and against any
            claims, liabilities, damages, losses, and expenses, including
            reasonable attorney's fees, arising out of or in any way connected
            with your use of URush or violation of these Terms & Conditions.
          </p>

          <h4>8. Changes to Terms & Conditions:</h4>
          <p>
            URush reserves the right to modify or replace these Terms &
            Conditions at any time. You are responsible for regularly reviewing
            these Terms & Conditions for updates.
          </p>

          <h4>9. Governing Law:</h4>
          <p>
            These Terms & Conditions shall be governed by and construed in
            accordance with the laws of [Jurisdiction], without regard to its
            conflict of law principles.
          </p>

          <h4>10. Contact Us:</h4>
          <p>
            If you have any questions or concerns about these Terms & Conditions
            or your use of URush, please contact us at{" "}
            <Link to="mailto:businessurush@gmail.com">
              businessurush@gmail.com
            </Link>
            .
          </p>

          <p>
            By using URush, you agree to the terms outlined in these Terms &
            Conditions.
          </p>
        </div>
      </div>
    </div>
  );
}
