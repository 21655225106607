import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { otpVerification } from "../Redux/Actions/Adminactions";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  otp: Yup.string().required("Otp is required").min(4, "otp must be atleast 4 characters"),
});

export default function LoginOtp() {

  const location=useLocation()
  console.log(location)

  const dispatch=useDispatch()
  const navigate=useNavigate()
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/logo-name.png")} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter OTP</h2>
                <Formik
                  initialValues={{ otp: "",adminId:location?.state?.adminId}}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    dispatch(otpVerification(values)).then((res)=>
                    {
                      if(res?.payload?.success){
                        toast.success(res?.payload?.message)
                        navigate("/change-password",{state:{
                          adminId:res?.payload?.data?._id
                        }})
                      }else{
                        toast.error(res?.payload?.message)
                      }
                      
                  })
                  }
                  }
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form>
                      <div className="form-set">
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Enter OTP</Form.Label>

                          <Field
                            type="password"
                            placeholder="Otp"
                            name="otp"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.otp}
                          />
                          <ErrorMessage className="text-danger" name="otp" component="div"/>
                        </Form.Group>
                      </div>

                      <div onClick={handleSubmit}>
                      <Link
                        // to="/"
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                       Verify OTP
                      </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
