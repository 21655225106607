import React from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip } from "recharts";
export default function ProductChart() {
  const data = [
    {
      name: "Mon",
      uv: 12,
    },
    {
      name: "Tue",
      uv: 6,
    },
    {
      name: "Wed",
      uv: 12,
    },
    {
      name: "Thu",
      uv: 6,
    },
    {
      name: "Fri",
      uv: 12,
    },
    {
      name: "Sat",
      uv: 3,
    },
    {
      name: "Sun",
      uv: 12,
    },
  ];
  return (
    <div className="user-graph">
      <AreaChart
        width={750}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="uv"
          stackId="1"
          stroke="#E03D84"
          fill="#6916AE"
        />
      </AreaChart>
    </div>
  );
}
