import React, { Suspense, lazy, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useDispatch, useSelector } from "react-redux";
import { profileshare } from "../Redux/Actions/Adminactions";

const Shareimg = lazy(()=>import("../Components/Shareimg"))

export default function UserProfileAdmin() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.profileData.profileData);
  console.log(data);
  // useEffect(() => {
  //   dispatch(profileshare(id));
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      dispatch(profileshare(id));
    }, 1000); // Add a delay of 1 second (1000 milliseconds)
  }, []);

  console.log(data?.data?.pictures)
  console.log(data?.data?.pictures?.length > 0)

    // const options = {
    //   items: 1,
    //   nav: false,
    // };
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <img
                className="img-fluid"
                src={require("../Assets/Images/logo-name.png")}
              />
            </div>
          </Col>
          <Col lg={6} className="mx-auto">
            <div className="fee-detail-box mt-5">
              <h2 className="mb-4">
                {data?.data?.full_name}
                , {data?.data?.age}
              </h2>
              {/* <OwlCarousel
                className="owl-theme add-positon-owl"
                loop
                margin={10}
                nav
                {...options}
              > */}

              <Suspense fallback={<p>Loading....</p>}>
                <Shareimg/>
              </Suspense>

              


                 {/* {data?.data?.pictures?.length > 0 ? (
                  data.data.pictures.map((x, index) => (
                    <div className="item" key={index}>
                      <img className="img-fluid" src={`${BASE_URL}/${x}`} />
                    </div>
                  ))
                ) : (
                  <div>Loading.....</div>
                )} */}
               
              {/* </OwlCarousel> */}

              <div className="send-msg-btn">
                {/* <Link to="">Send Message</Link> */}
              </div>
              <div className="profile-detail-list mb-5">
                <ul>
                  <li>
                    <label>I’m looking for</label>
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M12.2103 7.81287C12.2023 7.89434 12.2648 7.96594 12.3465 7.97173C12.7115 7.99761 13.0666 8.00071 13.4039 8.08005C14.6552 8.37408 15.4979 9.15347 15.8521 10.386C16.2077 11.6225 15.9192 12.7409 15.0168 13.668C14.1567 14.5521 13.0906 15.0999 11.9231 15.4604C10.3216 15.9545 8.67583 16.0706 7.01527 15.9632C6.25175 15.9135 5.49359 15.7786 4.73208 15.6907C4.59454 15.6745 4.54624 15.6128 4.52275 15.4846C4.17051 13.5834 4.09403 11.6749 4.40869 9.76302C4.66298 8.21968 5.17825 6.78242 6.20009 5.56198C6.91262 4.71076 7.79758 4.21534 8.9234 4.2187C10.8503 4.22407 12.3693 5.89092 12.2103 7.81287Z"
                            fill="url(#paint0_linear_585_1531)"
                          />
                          <path
                            d="M5.98002 1.30053C5.9907 1.31122 6.00921 1.30763 6.01873 1.29589C7.14841 -0.0975771 8.88674 -0.270599 10.119 0.335187C11.4228 0.975427 12.1239 2.29888 11.9693 3.85009C11.9618 3.92564 11.8729 3.96906 11.8144 3.92064C9.91839 2.39073 7.17562 2.58272 5.49963 4.35027C4.27652 5.64052 3.58278 7.19057 3.20437 8.90374C3.13452 9.21826 2.73712 9.32355 2.52906 9.07756C2.33792 8.85156 2.15368 8.62977 1.97858 8.40094C1.15534 7.32617 0.49917 6.1628 0.190541 4.83026C0.00200912 4.01462 -0.106682 3.1869 0.156324 2.36925C0.556199 1.12531 1.40694 0.330487 2.68574 0.0740483C3.95649 -0.180377 5.03467 0.227106 5.89615 1.19983C5.925 1.23272 5.95184 1.26696 5.98002 1.30053Z"
                            fill="url(#paint1_linear_585_1531)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_585_1531"
                              x1="0"
                              y1="0"
                              x2="16.5109"
                              y2="0.545814"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#6916AE" />
                              <stop offset="1" stop-color="#E03D84" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_585_1531"
                              x1="0"
                              y1="0"
                              x2="16.5109"
                              y2="0.545814"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#6916AE" />
                              <stop offset="1" stop-color="#E03D84" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      {data?.data?.lookingFor === 0 ? "Friendship" : "Dating"}
                    </p>
                  </li>
                  {/* <li>
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="16"
                          viewBox="0 0 14 16"
                          fill="none"
                        >
                          <path
                            d="M1.59261 5.04762C1.61309 2.55744 3.54651 0.457103 6.18858 0.0609089C8.99587 -0.360682 11.7444 1.45267 12.2947 4.06475C12.5214 5.14032 12.3234 6.15239 11.9001 7.14795C11.414 8.29209 10.7723 9.36258 10.0172 10.3683C9.23891 11.4058 8.40328 12.4064 7.59086 13.4223C7.5499 13.4731 7.50347 13.5213 7.45705 13.5683C7.18943 13.8375 6.78936 13.8477 6.53949 13.5632C4.901 11.7118 3.37447 9.78925 2.29853 7.59113C1.96127 6.9016 1.70048 6.18922 1.61719 5.42858C1.6049 5.30286 1.6008 5.17587 1.59261 5.04762ZM6.99144 7.07049C8.20802 7.0743 9.20341 6.15239 9.20068 5.02603C9.19795 3.90475 8.20939 2.98538 7.0051 2.98284C5.79125 2.9803 4.7945 3.90475 4.79586 5.03111C4.79859 6.15112 5.78305 7.06668 6.99144 7.07049Z"
                            fill="url(#paint0_linear_585_1550)"
                          />
                          <path
                            d="M6.9969 14.9042C8.41693 14.8775 9.81648 14.748 11.1682 14.3163C11.6297 14.169 12.0762 13.9874 12.4572 13.694C12.9146 13.3423 12.9119 13.0693 12.449 12.7226C11.9766 12.3709 11.4236 12.1791 10.8556 12.0115C10.5429 11.9201 10.3804 11.694 10.4091 11.4058C10.4336 11.1518 10.648 10.9499 10.9361 10.9181C11.0085 10.9105 11.0877 10.9143 11.1573 10.9334C11.8782 11.134 12.5719 11.3931 13.1699 11.8312C13.5932 12.1423 13.9127 12.5182 13.9864 13.0363C14.0493 13.4794 13.8895 13.8604 13.5973 14.1994C13.1467 14.7201 12.5309 15.0147 11.881 15.2522C10.8515 15.628 9.77415 15.821 8.67636 15.9214C7.34645 16.042 6.0179 16.028 4.69482 15.8503C3.50282 15.6903 2.33949 15.4363 1.27993 14.8763C0.918097 14.6845 0.59586 14.4483 0.347355 14.1334C-0.164674 13.4845 -0.107326 12.7226 0.497551 12.1169C0.960425 11.6547 1.55028 11.3791 2.16745 11.148C2.36407 11.0743 2.56615 11.0134 2.76823 10.9537C3.13006 10.847 3.46459 10.9943 3.56972 11.3016C3.67486 11.6089 3.4878 11.8896 3.13006 12.0204C2.68494 12.1829 2.24391 12.3607 1.81381 12.5537C1.64996 12.6274 1.50522 12.7531 1.37414 12.875C1.14339 13.0896 1.14202 13.2928 1.35366 13.5213C1.62675 13.8147 1.98585 13.9899 2.35997 14.1423C3.29664 14.5245 4.28656 14.7061 5.29697 14.8001C5.86088 14.8521 6.42889 14.8699 6.9969 14.9042Z"
                            fill="url(#paint1_linear_585_1550)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_585_1550"
                              x1="0"
                              y1="0"
                              x2="14.4507"
                              y2="0.417996"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#6916AE" />
                              <stop offset="1" stop-color="#E03D84" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_585_1550"
                              x1="0"
                              y1="0"
                              x2="14.4507"
                              y2="0.417996"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#6916AE" />
                              <stop offset="1" stop-color="#E03D84" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      3 kms away from you.
                    </p>
                  </li> */}
                  <li>
                    <label>Gender</label>
                    <p>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="16"
                          viewBox="0 0 11 16"
                          fill="none"
                        >
                          <path
                            d="M5.37407 15.999C4.98823 15.8709 4.83984 15.6055 4.85256 15.2183C4.86846 14.7449 4.8568 14.2705 4.8568 13.7962C4.8568 13.7409 4.8568 13.6845 4.8568 13.6148C4.46779 13.6148 4.09785 13.6159 3.72686 13.6138C3.63571 13.6138 3.54349 13.6118 3.45339 13.5974C3.12798 13.5462 2.90114 13.2696 2.9181 12.954C2.93506 12.6384 3.18946 12.3813 3.52123 12.371C3.90388 12.3587 4.28653 12.3659 4.66918 12.3649C4.72642 12.3649 4.78472 12.3649 4.85044 12.3649C4.85044 11.7645 4.85044 11.1836 4.85044 10.5893C2.97322 10.3178 1.54437 9.3957 0.640215 7.77382C0.0264887 6.67242 -0.142047 5.48394 0.117647 4.25959C0.678375 1.61726 3.0824 -0.100928 5.69842 0.00460188C8.47132 0.117303 10.6623 2.08548 10.9633 4.72167C11.1276 6.16322 10.7397 7.47158 9.80583 8.60884C8.87623 9.74098 7.64877 10.3854 6.16163 10.5821C6.16163 11.1764 6.16163 11.7614 6.16163 12.3649C6.4457 12.3649 6.72448 12.3649 7.00325 12.3649C7.15907 12.3649 7.31594 12.3618 7.47176 12.369C7.81519 12.3854 8.07383 12.6333 8.09079 12.955C8.10774 13.2839 7.87243 13.5708 7.52264 13.5995C7.19616 13.6261 6.86651 13.6128 6.53792 13.6159C6.41496 13.6169 6.29201 13.6159 6.15209 13.6159C6.15209 13.7583 6.15209 13.8874 6.15209 14.0165C6.15209 14.4171 6.13937 14.8187 6.15527 15.2193C6.17117 15.6066 6.02065 15.8719 5.63376 16C5.54684 15.999 5.45992 15.999 5.37407 15.999ZM5.48218 9.36906C7.78127 9.39365 9.67333 7.58838 9.70725 5.33845C9.74011 3.11106 7.86607 1.273 5.53306 1.24432C3.2255 1.21665 1.32496 3.0291 1.29952 5.28108C1.27408 7.51256 3.14918 9.34447 5.48218 9.36906Z"
                            fill="url(#paint0_linear_585_1557)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_585_1557"
                              x1="0"
                              y1="0"
                              x2="11.3578"
                              y2="0.258131"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#6916AE" />
                              <stop offset="1" stop-color="#E03D84" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      {data?.data?.gender}
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>University</label>
                    <p>{data?.data?.university}</p>
                  </li>
                  <li>
                    <label>Branch</label>
                    <p>{data?.data?.branch[0] || "N/A"}</p>
                  </li>
                  <li>
                    <label>Career</label>
                    <p>{data?.data?.careerPursuing}</p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <label>Who is {data?.data?.full_name}</label>
                    <p>{data?.data?.bio}</p>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
