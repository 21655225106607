import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteEvent, getEvents } from "../Redux/Actions/Adminactions";
import Pagination from "../Components/Layout/Pagination";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";

export default function Events() {
  const dispatch = useDispatch();
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const data = useSelector((state) => state.eventsData.eventdata);
  const [show, setshow] = useState(false);
  console.log(data);
  const navigate = useNavigate();
  const [id,setid] = useState("")

  const [pages, setpages] = useState(1);
  const [range, setRange] = useState(true);
  const [flag, setflag] = useState(true);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [filterDataByDate, setFilterDataByDate] = useState(null);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    dispatch(getEvents({ page: pages, limit: limit, search: search }));
  }, [flag, search, pages, limit]);

  const startformattedDate = moment(dateRange[0]?.startDate)?.format(
    "YYYY-MM-DD"
  );
  const endformattedDate = moment(dateRange[0]?.endDate)?.format("YYYY-MM-DD");

  useEffect(() => {
    let fetchdata = async () => {
      if (!initialRender) {
        const filterdata = data?.data?.events?.filter((x) => {
          return (
            x?.date?.slice(0, 10) >= startformattedDate &&
            x?.date?.slice(0, 10) <= endformattedDate
          );
        });

        setFilterDataByDate(filterdata);
      }
      setInitialRender(false);
    };
    fetchdata();
  }, [range]);

  const getStartDateText = () => {
    return dateRange[0]?.startDate
      ? dateRange[0]?.startDate?.toLocaleDateString()
      : "";
  };

  const handleDateFieldClick = () => {
    setPickerVisible(true);
  };

  const handleClose = () => {
    setPickerVisible(false);

    setflag(!flag);
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
    setFilterDataByDate(data);
  };

  const handleSelect = (ranges) => {
    setDateRange([ranges?.selection]);
  };

  // today

  const todayrange = () => {
    const currentDate = new Date();

    setDateRange([
      {
        startDate: currentDate,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  // yesterday

  const yesterdayrange = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    setDateRange([
      {
        startDate: yesterday,
        endDate: yesterday,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  // 7 days before

  const sevenDaysBefore = () => {
    const currentDate = new Date();
    const sevendays = new Date(currentDate);
    sevendays.setDate(currentDate.getDate() - 7);

    setDateRange([
      {
        startDate: sevendays,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  //close range

  const closerange = () => {
    setPickerVisible(false);
    setRange(!range);
  };

  console.log(filterDataByDate, "ijhojiio");
  // console.log(data?.data?.events)
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Events</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                    type="search"
                    placeholder="Keyword Search.."
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <input
                  type="text"
                  className="form-control"
                  value={`${getStartDateText()}     ${
                    dateRange[0]?.endDate?.toLocaleDateString() ||
                    "Select date range"
                  }`}
                  onClick={handleDateFieldClick}
                  readOnly
                />

                {pickerVisible && (
                  <div
                    className="date-picker"
                    style={{
                      width: "500px",
                      height: "500px",
                      overflow: "scroll",
                      position: "absolute",
                    }}
                  >
                    <div className="d-flex gap-3 justify-content-end m-2 date-range-filters">
                      <Button onClick={handleClose}>Clear</Button>
                    </div>
                    <DateRange
                      ranges={dateRange}
                      onChange={handleSelect}
                      months={2}
                      direction="horizontal"
                    />
                    <div className="d-flex gap-3 justify-content-between date-range-filters">
                      <div className="d-flex gap-3">
                        <Button onClick={todayrange}>Today</Button>
                        <Button onClick={yesterdayrange}>Yesterday</Button>
                        <Button onClick={sevenDaysBefore}>before 7 days</Button>
                      </div>
                      <div>
                        <Button onClick={closerange}>Ok</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Col>

              {/* <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="Date" placeholder="Keyword Search.." />
                </Form.Group>
              </Col> */}
              <Col className="d-flex justify-content-end align-items-center">
                {/* <div className="cmn-btn">
                  <Link to="/EventDetails"> CREATE EVENT</Link>
                </div> */}
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {filterDataByDate?.length >= 0
                  ? filterDataByDate?.length
                  : data?.data?.events?.length + (pages - 1) * limit}{" "}
                of{" "}
                {filterDataByDate?.length >= 0
                  ? filterDataByDate?.length
                  : data?.data?.totalEvents}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Created by</th>
                <th>Create on date</th>
                <th>Email</th>
                <th>Date & Time</th>
                <th>Number of Guests</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(filterDataByDate?.length >= 0
                ? filterDataByDate
                : data?.data?.events
              )?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/EventDetails", {
                          state: {
                            name: "view",
                            id: x?._id,
                          },
                        })
                      }
                    >
                      <b>{x?.created_by?.full_name || "N/A"}</b>
                    </td>
                    <td>
                      {new Date(x?.createdAt).toLocaleDateString()}
                      <br />
                      {/* <span className="time">12:03</span> */}
                    </td>
                    <td>
                      <Link to="/Supportchat">
                        {x?.created_by?.email || "N/A"}
                      </Link>
                    </td>
                    <td>
                      {new Date(x?.date).toLocaleDateString()} at {x?.time}
                    </td>
                    <td>
                      N/A
                      <br />
                      {/* <span className="time">12:03</span> */}
                    </td>
                    <td>{x?.status == 0 ? "Opened" : "Closed"}</td>
                    <td>
                      <Button onClick={()=>{
                        setid(x?._id)
                        setshow(true)}} className="account-btn common-colr-btn">
                        DELETE
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {filterDataByDate?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
          {data?.data?.events?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.data?.totalEvents}
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button
            onClick={() => {
              dispatch(deleteEvent({ id: id })).then((res) => {
                if (res?.payload?.success) {
                  setflag(!flag);
                  setshow(false);
                }
              });
            }}
            className="theme-btn"
          >
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
