import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { login } from "../Redux/Actions/Adminauth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FiCloudLightning } from "react-icons/fi";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email Address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be atleast 6 characters"),
});

export default function Login() {
  const dispatch = useDispatch();

  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <div className="logo">
                <img src={require("../Assets/Images/logo-name.png")} />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="box-center">
            <Col lg={12} className="m-auto">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2 className="text-center">Log in</h2>

                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      try {
                        let data = await dispatch(login(values));
                        console.log(data);
                        if (data?.payload?.status_code === 200) {
                          toast.success(data?.payload?.message);

                          setTimeout(() => {
                            toast.dismiss();
                            window.location.href = "Dashboard";
                          }, 2000);

                          // navigate("/Dashboard")
                        } else {
                          toast.error("Invalid Credentials");
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {({ values, handleChange, handleSubmit }) => (
                      <Form>
                        <div className="form-set">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Field
                              type="email"
                              placeholder="Email"
                              name="email"
                              as={Form.Control}
                              onchange={handleChange}
                              value={values.email}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="email"
                              component="div"
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Password</Form.Label>
                            <Field
                              type="password"
                              placeholder="Password"
                              name="password"
                              as={Form.Control}
                              onchange={handleChange}
                              value={values.password}
                            />
                            <ErrorMessage
                              className="text-danger"
                              name="password"
                              component="div"
                            />
                          </Form.Group>
                        </div>
                        <button
                          // to="/Dashboard"
                          variant="primary"
                          type="submit"
                          className="submit"
                          onClick={handleSubmit}
                        >
                          Log In
                        </button>
                        <Link to="/Forgot-Password" className="forgot">
                          Forgot Password?
                        </Link>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="d-flex justify-content-center">
        <Link to="/TermsAndConditions" className="forgot me-3">
          Terms and Conditions
        </Link>

        <Link to="/PrivacyPolicy" className="forgot me-3">
          Privacy Policy
        </Link>

        <Link to="/Contact-us" className="forgot me-3">
          Contact Us
        </Link>
      </div>
    </>
  );
}
