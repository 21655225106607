import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { changeReportStatus, changesupportStatus, supportData } from "../Redux/Actions/Adminactions";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../Components/Layout/Pagination";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";

export default function Support() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.supportData.supportList);
  console.log(data);

  const [pages, setpages] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [filterDataByDate, setFilterDataByDate] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [range, setRange] = useState(true);
  const [flag, setflag] = useState(true);

  useEffect(() => {
    dispatch(supportData({ page: pages, limit: limit, search: search }));
  }, [search, pages, limit, flag]);

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(supportData());
      console.log(response);

      const allData = response?.payload?.message;
      console.log(allData);

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  const startformattedDate = moment(dateRange[0]?.startDate)?.format(
    "YYYY-MM-DD"
  );
  const endformattedDate = moment(dateRange[0]?.endDate)?.format("YYYY-MM-DD");

  useEffect(() => {
    let fetchdata = async () => {
      if (!initialRender) {
        const filterdata = data?.data?.supports?.filter((x) => {
          return (
            x?.createdAt?.slice(0, 10) >= startformattedDate &&
            x?.createdAt?.slice(0, 10) <= endformattedDate
          );
        });

        setFilterDataByDate(filterdata);
      }
      setInitialRender(false);
    };
    fetchdata();
  }, [range]);

  const getStartDateText = () => {
    return dateRange[0]?.startDate
      ? dateRange[0]?.startDate?.toLocaleDateString()
      : "";
  };

  const handleDateFieldClick = () => {
    setPickerVisible(true);
  };

  const handleClose = () => {
    setPickerVisible(false);

    setflag(!flag);
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
    setFilterDataByDate(data);
  };

  const handleSelect = (ranges) => {
    setDateRange([ranges?.selection]);
  };

  // today

  const todayrange = () => {
    const currentDate = new Date();

    setDateRange([
      {
        startDate: currentDate,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  // yesterday

  const yesterdayrange = () => {
    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    setDateRange([
      {
        startDate: yesterday,
        endDate: yesterday,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  // 7 days before

  const sevenDaysBefore = () => {
    const currentDate = new Date();
    const sevendays = new Date(currentDate);
    sevendays.setDate(currentDate.getDate() - 7);

    setDateRange([
      {
        startDate: sevendays,
        endDate: currentDate,
        key: "selection",
      },
    ]);
    setRange(!range);
    setPickerVisible(false);
  };

  //close range

  const closerange = () => {
    setPickerVisible(false);
    setRange(!range);
  };

  console.log(filterDataByDate, "asdwqdsadqw3dsadw3dwsdqw3dewdw3");
  console.log(data?.data?.supports[0]?.status);

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Support</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={(e) => setsearch(e.target.value)}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              <Col xxl={3} xl={3} lg={4} md={4}>
                {/* <Form.Group>
                  <Form.Control type="date" placeholder="Keyword Search.." />
                </Form.Group> */}
                <input
                  type="text"
                  className="form-control"
                  value={`${getStartDateText()}     ${
                    dateRange[0]?.endDate?.toLocaleDateString() ||
                    "Select date range"
                  }`}
                  onClick={handleDateFieldClick}
                  readOnly
                />

                {pickerVisible && (
                  <div
                    className="date-picker"
                    style={{
                      width: "500px",
                      height: "500px",
                      overflow: "scroll",
                      position: "absolute",
                    }}
                  >
                    <div className="d-flex gap-3 justify-content-end m-2 date-range-filters">
                      <Button onClick={handleClose}>Clear</Button>
                    </div>
                    <DateRange
                      ranges={dateRange}
                      onChange={handleSelect}
                      months={2}
                      direction="horizontal"
                    />
                    <div className="d-flex gap-3 justify-content-between date-range-filters">
                      <div className="d-flex gap-3">
                        <Button onClick={todayrange}>Today</Button>
                        <Button onClick={yesterdayrange}>Yesterday</Button>
                        <Button onClick={sevenDaysBefore}>before 7 days</Button>
                      </div>
                      <div>
                        <Button onClick={closerange}>Ok</Button>
                      </div>
                    </div>
                  </div>
                )}
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link onClick={fetchDataAndExportToExcel}>DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr/>
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Support" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="#">Open 18</Link>
              </li>
              <li>
                <Link to="#">Closed 160</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {" "}
                Showing {1 + (pages - 1) * limit} -{" "}
                {filterDataByDate?.length >= 0
                  ? filterDataByDate?.length
                  : data?.data?.supports?.length + (pages - 1) * limit}{" "}
                of{" "}
                {filterDataByDate?.length >= 0
                  ? filterDataByDate?.length
                  : data?.data?.total}{" "}
                results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Date</th>
                <th>Full Name</th>
                <th>EMAIL</th>
                <th>Message</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {(filterDataByDate?.length >= 0
                ? filterDataByDate
                : data?.data?.supports
              )?.map((x, index) => {
                const serialNumber = (pages - 1) * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>{new Date(x?.createdAt).toLocaleDateString()}</td>
                    <td>
                      <Link to={`/SupportChat/${x?._id}`}>
                        <b>{x?.user_id?.full_name || "N/A"}</b>
                      </Link>
                    </td>
                    <td>{x?.user_id?.email || "N/A"}</td>
                    <td>{ x?.message || "N/A"}</td>
                    <td>{x?.status == 0 ? "Open" : "Close"}</td>
                    <td className="select-box">
                      <Form.Select
                        onChange={(e) => {
                          dispatch(
                            changesupportStatus({
                              id: x?._id,
                              status: e.target.value,
                            })
                          ).then((res) => {
                            if (res?.payload?.success) {
                              setflag(!flag);
                            }
                          });
                        }}
                         value={x?.status}
                        aria-label="Default select example"
                      >
                        <option value="1">Close</option>
                        <option value="0">Open</option>
                      </Form.Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {filterDataByDate?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
          {data?.data?.supports?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )}
        </div>
      </Container>

      <Pagination
        totalstuff={data?.data?.total}
        limit={data?.data?.limit}
        setpages={setpages}
        search={search}
      />
    </Layout>
  );
}
