import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Layout from "../Components/Layout/Layout";
import { Button, Figure, Modal, Toast } from "react-bootstrap";
import {
  ActivateDeactivate,
  AdminActivity,
  getuserprofile,
} from "../Redux/Actions/Adminactions";
import { useDispatch, useSelector } from "react-redux";
import { deleteaccount } from "../Redux/Actions/Adminactions";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/Images/logo-name.png";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function CustomerProfile() {
  const data = useSelector((state) => state.usersdata.profileData);
  const adminActivity = useSelector((state) => state.usersdata.ActivityData);

  console.log(data, "");

  const location = useLocation();
  // console.log(location)
  const navigate = useNavigate();
  const userData = location?.state?.id;
  const [selectedImage, setSelectedImage] = useState(null);
  const [Age, setAge] = useState(null);
  const [show, setshow] = useState(false);
  const [showb, setshowb] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getuserprofile({ id: userData }));
  }, [userData]);

  // console.log(data?.message?._id, "fujugf");

  useEffect(() => {
    dispatch(AdminActivity(userData));
  }, [userData]);

  const handledelete = () => {
    dispatch(deleteaccount({ id: userData })).then((res) => {
      if (res?.payload?.data?.success) {
        toast.success(res?.payload?.data?.message);
        navigate("/UserManagement");
      } else {
        toast.error(res?.payload?.data?.message);
      }
    });
  };

  const handlesuspend = () => {
    dispatch(
      ActivateDeactivate({
        id: userData,
        action: "ban",
      })
    ).then((res) => {
      if (res?.payload?.data?.success) {
        toast.success(res?.payload?.data?.message);
        setshowb(false);
      } else {
        toast.error(res?.payload?.data?.message);
      }
    });
  };

  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear();

    let age = year - data?.message?.dob?.slice(0, 4);
    console.log(age, "iuouio");
    setAge(age);
    // console.log(data?.message?.dob?.slice(0,4))
  }, [data]);

  const openLightbox = (index) => {
    setSelectedImage(index);
  };
  console.log(selectedImage);

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Customer Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to={location?.state?.path}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              {" "}
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <div className="customer-form-new mb-4">
                      <Container className="customer-form-inner">
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Image</Form.Label>
                            <div className="image-upload">
                              <input
                                type="file"
                                className="form-control"
                                // onChange={handleChange}
                              />
                              <p>Upload Image</p>
                              <img
                                className="preview-img"
                                src={
                                  data?.message?.profile_image
                                    ? `https://urush-api.bosselt.com/${data?.message?.profile_image}`
                                    : Logo
                                }
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Full name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={data?.message?.full_name || "N/A"}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Age</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={Age || "N/A"}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Gender</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={data?.message?.gender || "N/A"}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Location</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={data?.message?.address || "N/A"}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="mb-3">
                              <Form.Label>Bio</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Type Here"
                                value={data?.message?.bio || "N/A"}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main mt-4 mb-4 px-3 py-4 ">
                      <div className="property-main mt-0">
                        <div className="profile-extra-details">
                          <ul>
                            <li>
                              <h2>Who can see you profile</h2>
                              <p>
                                {data?.message?.visibleProfileTo === 0
                                  ? "Men"
                                  : data?.message?.visibleProfileTo === 1
                                  ? "Women"
                                  : "Everyone"}
                              </p>
                            </li>
                            <li>
                              <h2>Who do you prefer?</h2>
                              <p>
                                {data?.message?.yourPreference === 0
                                  ? "Men"
                                  : data?.message?.visibleProfileTo === 1
                                  ? "Women"
                                  : "Everyone"}
                              </p>
                            </li>
                            <li>
                              <h2>Right now I’m looking for</h2>
                              <p>
                                {data?.message?.looking_for === 0
                                  ? "Friendship"
                                  : "Dating"}
                              </p>
                            </li>
                            <li>
                              <h2>Where do you study?</h2>
                              <p>{data?.message?.university || "N/A"}</p>
                            </li>
                            <li>
                              <h2>What is your educational field?</h2>
                              {data?.message?.branch?.map((x) => (
                                <p>{x?.eng}</p>
                               ))}
                            </li>
                            <li>
                              <h2>What career are you pursuing?</h2>
                              <p>{data?.message?.careerPursuing}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={4}>
                <Row>
                  <Col lg={12}>
                    <Col lg={12}>
                      <div className="user-profile-main mb-4 px-3 py-4 ">
                        <div className="property-main mt-0">
                          <h2 className="property mb-3">Photos</h2>

                          <Row>
                            {data?.message?.pictures?.map((x, index) => {
                              return (
                                // <Col lg={4} className="mb-3">
                                //    <div onClick={() => openLightbox(index)}>
                                //   <img
                                //     style={{
                                //       maxWidth: "100%",
                                //       maxHeight: "200px",
                                //       margin: "5px",
                                //       cursor: "pointer",
                                //     }}
                                //     className="profile-add-img"
                                //     src={`https://urush-api.bosselt.com/${x}`}
                                //   />
                                //   </div>
                                // </Col>
                                <Col lg={4} className="mb-3">
                                  <img
                                    onClick={() => openLightbox(index)}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "200px",
                                      margin: "5px",
                                      cursor: "pointer",
                                    }}
                                    className="profile-add-img"
                                    src={`https://urush-api.bosselt.com/${x}`}
                                  />
                                </Col>
                              );
                            })}
                            {data?.message?.pictures?.length == 0 &&
                              "No Data Available Yet"}
                            {selectedImage !== null && (
                              <div className="lightbox">
                                <span className="close" onClick={closeLightbox}>
                                  &times;
                                </span>
                                <img
                                  src={`${`https://urush-api.bosselt.com/`}${data?.message?.pictures[selectedImage]}`}
                                  // alt={`Image ${selectedImage}`}
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "80vh",
                                    height:'500px'
                                  }}
                                />
                              </div>
                            )}

                            {/* <Col lg={4} className="mb-3">
                              <img
                                className="profile-add-img"
                                src={require("../Assets/Images/profile-img.png")}
                              />
                            </Col>
                            <Col lg={4} className="mb-3">
                              <img
                                className="profile-add-img"
                                src={require("../Assets/Images/profile-img.png")}
                              />
                            </Col>
                            <Col lg={4} className="mb-3">
                              <img
                                className="profile-add-img"
                                src={require("../Assets/Images/profile-img.png")}
                              />
                            </Col>
                            <Col lg={4} className="mb-3">
                              <img
                                className="profile-add-img"
                                src={require("../Assets/Images/profile-img.png")}
                              />
                            </Col> */}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="user-profile-main px-3 mt-4 py-4 h-100">
                      <div className="property-main mt-0">
                        <h2 className="property">Activity</h2>
                        <div className="active-default-links">
                          <ul>
                            {adminActivity?.data &&
                              adminActivity?.data?.map((x) => {
                                return (
                                  <li>
                                    <h6>
                                      {x?.updatedBy
                                        ? `[${x?.updatedBy}]`
                                        : null}{" "}
                                      {x?.title}
                                    </h6>
                                    <p>
                                      {` ${new Date(
                                        x?.updatedAt
                                      ).toLocaleDateString()} 
                                    ${new Date(
                                      x?.updatedAt
                                    ).toLocaleTimeString()}`}
                                    </p>
                                  </li>
                                );
                              })}

                            {adminActivity?.data?.length == 0 &&
                              "No Data Available Yet"}

                            {/* <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li>
                            <li>
                              <h6>[Admin Name] lorem ipsum is dummy text.</h6>
                              <p>01/01/2023 13:32</p>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Delete Account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            Once you delete your account, you can not retrieve
                            the account. Please be certain.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a onClick={()=>setshow(true)}>Delete Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} className="mb-5">
                <div className="user-profile-main px-3 py-4 h-100">
                  <Row>
                    <Col lg={12}>
                      <div className="property-main mt-0">
                        <h2 className="property">Suspend account</h2>
                        <Figure.Caption className="m-0 mt-2">
                          <p>
                            You will not be able to receive messages,
                            notifications for up to 24hours.
                          </p>
                        </Figure.Caption>
                        <div class="dlt-ac-btn mt-4">
                          <a onClick={()=>setshowb(true)}>Suspend Account</a>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {/* <Modal show={show} animation={false}>
        <Modal.Body >
          <p className="modal_head ">
            Are you sure you want to Delete this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button  variant="primary"  onClick={handledelete}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/* <Modal show={showb} animation={false}>
        <Modal.Body>
          <p className="modal_head ">
            Are you sure you want to Suspend this account?
          </p>
        </Modal.Body>
        <Modal.Footer className="m-auto">
          <Button variant="primary" onClick={handlesuspend}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => setshowb(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handledelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showb} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlesuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowb(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
