import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { eventshare, getEvents } from "../Redux/Actions/Adminactions";

export default function FeedDetails() {
  
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector((state)=>state.eventsData.eventsahre)
  console.log(data)



  useEffect(() => {
    dispatch(eventshare(id));
  }, [id]);
  return (
    <>
      <Container>
        <Row>
          <Col lg={6} className="mx-auto">
            <div className="fee-detail-box mt-5">
              <h2 className="mb-4">{data?.data?.title || "N/A"}</h2>
              <img
                className="img-fluid"
                src={`${BASE_URL}/${data?.data?.image}`}
              />
              <div className="mt-3 mb-2 d-flex justify-content-between date-tym">
                <p>
                  <span>Date:</span> {new Date(data?.data?.date).toLocaleDateString()}
                </p>
                <p>
                  <span>Time:</span> {data?.data?.time}
                </p>
              </div>
              <p>
               {data?.data?.description}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
