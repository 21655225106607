import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "../Redux/Actions/Adminactions";
import Pagination from "../Components/Layout/Pagination";
import * as XLSX from "xlsx";
import { Button, Toast } from "react-bootstrap";
import { getAdvertisment } from "../Redux/Actions/Adminactions";
import Logo from "../Assets/Images/Vector  6 .svg";
import { deleteAds } from "../Redux/Actions/Adminactions";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

export default function ManageAdvertisment() {
  const dispatch = useDispatch();
  // const data = useSelector((state) => state.reportsData.data);
  // console.log(data);
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [pagination, setpagination] = useState(null);
  const [flag, setflag] = useState(true);
  const [Adsdata, setAdsdata] = useState([]);
  const [show, setshow] = useState(false);
  const [id, setid] = useState(false);
  console.log(Adsdata);

  // useEffect(() => {

  //   dispatch(getReports({ page: pages, limit: limit, search: search }));
  // }, [flag, pages, limit, search]);

  // const fetchDataAndExportToExcel = async () => {
  //   try {
  //     const response = await dispatch(getReports());
  //     console.log(response);

  //     // const allData = response?.payload?.message
  //     // console.log(allData)

  //     // const ws = XLSX.utils.json_to_sheet(allData);

  //     // const wb = XLSX.utils.book_new();
  //     // XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

  //     // XLSX.writeFile(wb, "exported_data.xlsx", {
  //     //   bookType: "xlsx",
  //     //   bookSST: false,
  //     //   type: "blob",
  //     // });
  //   } catch (error) {
  //     console.error("Error fetching or exporting data:", error);
  //   }
  // };

  useEffect(() => {
    setpagination(Math.ceil(Adsdata?.totalCount / limit));
  }, [Adsdata]);

  const handlepagination = (page) => {
    console.log(page);
    setpage(page);
  };

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  useEffect(() => {
    const getdata = async () => {
      try {
        const data = await dispatch(
          getAdvertisment({ page: page, limit: limit, search: search })
        );
        console.log(data);
        if (data?.payload?.data?.status_code === 200) {
          setAdsdata(data?.payload?.data?.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getdata();
  }, [page, limit, search, flag]);

  const handleDeleteAds = async () => {
    try {
      const data = await dispatch(deleteAds({ addsId: id }));
      console.log(data);
      if (data?.payload?.status == 200) {
        toast.success(data?.payload?.data?.message);
        setflag(!flag);
        setshow(false);
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Manage Advertisment</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    onChange={handlesearch}
                    type="search"
                    placeholder="Search.."
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/CreateAdvertisment">Create</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {/* Showing {1 + (pages - 1) * limit} -{" "}
                {data?.data?.reports?.length + (pages - 1) * limit} of{" "}
                {data?.data?.totalUser}{" "} */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  name="limit"
                  onChange={handlelimit}
                  aria-label="Default select example"
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No.</th>
                <th>Title</th>
                <th>Image</th>
                <th>Audio</th>
                <th>ACTION </th>
              </tr>
            </thead>
            <tbody>
              {Adsdata?.allAds?.map((data, index) => {
              const serialNumber = (page - 1) * limit + index + 1;
              console.log(data?.image_video)
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <p className="link-tag" >
                        {data?.title}
                      </p>
                    </td>
                    {/* <td
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate("/ReportDetails", {
                          state: {
                            id: x?._id,
                          },
                        })
                      }
                    >
                      <b>{x?.block_by?.full_name}</b>
                    </td>
                    <td>{x?.block_to?.full_name}</td>
                    <td>{x?.title}</td>
                    <td>{x?.description}</td> */}
                    <td className="user-img">
                      <img
                        src={
                          data?.image_video
                            ? `https://urush-api.bosselt.com/${data?.image_video}`
                            
                            : Logo
                        }
                      />
                    </td>
                    <td>{data?.audio}</td>

                    <td>
                      <Button
                        style={{ cursor: "pointer" }}
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setshow(true);
                          setid(data?._id);
                        }}
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {/* {data?.data?.reports?.length == 0 && (
            <div className="no-data-updated">
              <p className="no-data">No data available yet</p>
            </div>
          )} */}
        </div>
      </Container>

      <Pagination
        totalstuff={Adsdata?.totalCount}
        limit={Adsdata?.totalCount / Adsdata?.totalPages}
        setpages={setpage}
        search={search}
      />
      

      {/* Delete Advertisement */}

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Delete</h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button class="btn btn-secondary " onClick={handleDeleteAds}>
            Yes
          </Button>
          <Button class="btn btn-secondary " onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
