import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { addEvent, eventById } from "../Redux/Actions/Adminactions";
import moment from "moment";

export default function EventDetails() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const dispatch = useDispatch();
  const location = useLocation();
  const data = useSelector((state) => state.eventsData.eventdatabyid);
  console.log(data);
  const [file, setFile] = useState();
  const [date, setDate] = useState();
  // const navigate = useNavigate();
  // const [eventdetails, setEventdetails] = useState({
  //   description: data?.data?.description ? data?.data?.description : "",
  //   date: date ? date : "",
  //   time: data?.data?.time ? data?.data?.time : "",
  //   image: "",
  //   title: data?.data?.title ? data?.data?.title : "",
  // });
  // const [error, setError] = useState({
  //   descriptionerr: "",
  //   dateerr: "",
  //   timeerr: "",
  //   imageerr: "",
  //   titleerr: "",
  // });

  // const handleChange = (e) => {
  //   setEventdetails((old) => ({ ...old, [e.target.name]: e.target.value }));
  //   setError((old) => ({ ...old, [`${e.target.name}err`]: "" }));
  // };

  // const uploadimage = (e) => {
  //   setEventdetails((old) => ({ ...old, image: e.target.files[0] }));
  //   setFile(URL.createObjectURL(e.target.files[0]));
  //   setError((old) => ({ ...old, imageerr: "" }));
  // };

  // const handlesubmit = () => {
  //   validation();
  // };

  // const validation = () => {
  //   let isvalid = true;
  //   let err = {
  //     descriptionerr: "",
  //     dateerr: "",
  //     timeerr: "",
  //     imageerr: "",
  //     titleerr: "",
  //   };

  //   if (!eventdetails?.description) {
  //     err.descriptionerr = "Required*";
  //     isvalid = false;
  //   }
  //   if (!eventdetails?.date) {
  //     err.dateerr = "Required*";
  //     isvalid = false;
  //   }
  //   if (!eventdetails?.time) {
  //     err.timeerr = "Required*";
  //     isvalid = false;
  //   }
  //   if (!eventdetails?.image) {
  //     err.imageerr = "Required*";
  //     isvalid = false;
  //   }
  //   if (!eventdetails?.title) {
  //     err.titleerr = "Required*";
  //     isvalid = false;
  //   }
  //   if (!isvalid) {
  //     setError(err);
  //   }
  //   if (isvalid) {
  //     const formdata = new FormData();
  //     formdata.append("description", eventdetails?.description);
  //     formdata.append("image", eventdetails?.image);
  //     formdata.append("date", eventdetails?.date);
  //     formdata.append("title", eventdetails?.title);
  //     formdata.append("time", eventdetails?.time);

  //     dispatch(addEvent(formdata)).then((res) => {
  //       if (res?.payload?.success) {
  //         navigate("/Events");
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    dispatch(eventById(location?.state?.id));
  }, [location?.state?.id]);

  useEffect(() => {
    // setDate(moment(data?.data?.date).format("DD-MM-YYYY"));
    setFile(`${BASE_URL}/${data?.data?.image}`)
  }, [data]);

  // console.log(date);
  // console.log(new Date(data?.data?.date).toLocaleDateString())

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            {/* {location?.state?.name ? ( */}
              <h2>Event Details</h2>
            {/* ) : ( */}
              {/* <h2>Create Event</h2> */}
            {/* )} */}
          </div>
          <div></div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Events">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            ></Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form">
                  <h2>Events Details</h2>
                  <Row>
                    <Col lg={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Image</Form.Label>
                        <div className="event-image-upload">
                          <input
                            type="file"
                            name="image"
                            // value={file}
                            className="form-control"
                            // onChange={uploadimage}
                          />
                          <p>Upload Image</p>
                          <img className="preview-img"
                           src={file} 

                           />
                        </div>
                        {/* <Form.Label className="text-danger">
                          {error?.imageerr}
                        </Form.Label> */}
                      </Form.Group>
                    </Col>
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Title</Form.Label>
                          <Form.Control
                            name="title"
                            value={data?.data?.title}
                            // onChange={handleChange}
                            type="text"
                            placeholder="Type Here"
                          />
                          {/* <Form.Label className="text-danger">
                            {error?.titleerr}
                          </Form.Label> */}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            name="date"
                            value={new Date(data?.data?.date).toLocaleDateString()}
                            // onChange={handleChange}
                            type="text"
                            placeholder="Type Here"
                          />
                          {/* <Form.Label className="text-danger">
                            {error?.dateerr}
                          </Form.Label> */}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Time</Form.Label>
                          <Form.Control
                            name="time"
                            value={data?.data?.time}
                            // onChange={handleChange}
                            type="text"
                            placeholder="Type Here"
                          />
                          {/* <Form.Label className="text-danger">
                            {error?.timeerr}
                          </Form.Label> */}
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            name="description"
                            value={data?.data?.description}
                            // onChange={handleChange}
                            placeholder="Type Here"
                          />
                          {/* <Form.Label className="text-danger">
                            {error?.descriptionerr}
                          </Form.Label> */}
                        </Form.Group>
                      </Col>

                      
                        {/* <Col lg={4}>
                          <div onClick={handlesubmit} className="event-dlt-btn">
                            <Link to="#">Create</Link>
                          </div>
                        </Col> */}
                      
                    </Row>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
         
            <Col lg={4}>
              <div className="product-overview-right">
                <div className="product-overview-box">
                  <h2>STATUS</h2>
                  <div className="event-dlt-btn">
                    <Link to="#">{data?.data?.status=="0"?"Opened" : "Closed"} </Link>
                  </div>
                </div>
              </div>
            </Col>
         
        </Row>
      </Container>
    </Layout>
  );
}
