import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  GetDeleteRequest,
  Subscribed,
  deleteaccount,
  userManagement,
} from "../Redux/Actions/Adminactions";
import { useDispatch } from "react-redux";
import Pagination from "../Components/Layout/Pagination";
import Logo from "../Assets/Images/Vector  6 .svg";
import * as XLSX from "xlsx";
import { dowanloadsubscribers } from "../Redux/Actions/Adminactions";

export default function DeleteAccountRequest() {
  const [pagination, setpagination] = useState(null);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState("");
  const [page, setpage] = useState(1);
  const [details, setDetails] = useState([]);
  
  const [show, setshow] = useState(false);
  const [id, setid] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(
        GetDeleteRequest({ page: page, search: search, limit: limit })
      );
      console.log(data);
      if (data?.payload?.status === 200) {
        setDetails(data?.payload?.data?.data);
      }
    };
    getdata();
  }, [search, page, limit]);


  const handledelete = () => {
    dispatch(deleteaccount({ id:id  })).then((res) => {
      if (res?.payload?.data?.success) {
        toast.success(res?.payload?.data?.message);
      } else {
        toast.error(res?.payload?.data?.message);
      }
    });
  };

  // useEffect(() => {
  //   setpagination(
  //     Math.ceil((userparms?.search ? 1 : data?.totalUsers) / data?.limit)
  //   );
  // }, [data, userparms]);

  // const handlePages = (page) => {
  //   setuserparms((old) => ({ ...old, page: page }));
  // };

  const handlesearch = (e) => {
    setsearch((old) => ({ ...old, search: e.target.value }));
  };

  const handlelimit = async (e) => {
    setlimit(e.target.value);
  };

  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Delete Account users</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              {/* <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Select
                  aria-label="Default select example"
                 
                >
                  <option value="2">All</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </Form.Select>
              </Col> */}
              {/* <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link >DOWNLOAD</Link>
                </div>
              </Col> */}
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserVerification" className="active-tab">
                  ALL
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {/* {" "}
                Showing {1 + (userparms?.page - 1) * limit} -{" "}
                {Subscribers?.length + (userparms?.page - 1) * limit} of{" "}
                {data?.totalUsers}{" "} */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={handlelimit}
                >
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Reson</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {details?.map((Data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    
                    <td>{Data?.user_id?.full_name}</td>
                    <td>{Data?.email}</td>
                    <td>
                      {Data?.reason === 1
                        ? "I don’t like URush"
                        : Data?.reason === 2
                        ? "I can’t use URush"
                        : Data?.reason === 3
                        ? "I found love"
                        : "Others"}
                    </td>
                    <td>
                      <Button
                        style={{ cursor: "pointer" }}
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setshow(true);
                          setid(Data?._id);
                        }}
                      >
                        DELETE
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Pagination
      // totalstuff={data?.totalUsers}
      // limit={data?.limit}
      // setpages={userparms?.page}
      // search={userparms?.search}
      />


<Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">Are you sure you want to Delete</h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button class="btn btn-secondary " onClick={handledelete}>
            Yes
          </Button>
          <Button class="btn btn-secondary " onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
