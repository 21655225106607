import React from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { changepassword } from "../Redux/Actions/Adminactions";
import { useNavigate } from "react-router-dom";



const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required("Required*"),
  newPassword: Yup.string().required("Required*"),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
  .required("Required*"),
})

export default function ChangePassword() {

  const dispatch = useDispatch()
  const navigate=useNavigate()
  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr/>
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>
             
                <Formik  initialValues={{ oldPassword: "", newPassword: "", confirmPassword:"" }}
                validationSchema={validationSchema}
                onSubmit={async(values, ) => {
                  console.log(values)
                  try {
                    let data = await dispatch(changepassword(values));
                    console.log(data);
                    if (data?.payload?.status_code === 200) {
                      toast
                      .success(data?.payload?.message);

                      // window.location.href = "/Otp";
                      navigate("/") 
                     
                    } else {
                      toast.error("Old password is incorrect");
                    }
                  } catch (error) {
                    console.log(error);
                  }
                }}>
                  
                  {({
                    values,
                    handleSubmit,
                    handleChange,
                  }) => (
                <Form className="change-password-form px-5">
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Old Password</Form.Label>
                      <Field
                        type="Password"
                        name="oldPassword"
                        placeholder="Old Password"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.oldPassword}
                      />
                      <ErrorMessage className="text-danger" name="oldPassword" component="div"/>
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>New Password</Form.Label>
                      <Field
                        type="Password"
                        name="newPassword"
                        placeholder="New Password"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.newPassword}
                      />
                      <ErrorMessage className="text-danger" name="newPassword" component="div" />
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Confirm Password</Form.Label>
                      <Field
                        type="Password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        as={Form.Control}
                        onChange={handleChange}
                        value={values.confirmPassword}
                      />  
                      <ErrorMessage className="text-danger" name="confirmPassword" component="div"/>
                    </Form.Group>
                  </Row>
                  <Form.Group
                    className="mt-4  mb-5 model-btn text-center"
                    controlId="formGridAddress2"
                  >
                    <div class="cmn-btn">
                      <Button type="submit" onClick={handleSubmit} >SAVE</Button>
                    </div>
                  </Form.Group>
                </Form>
                )}
                </Formik>
              </Card>
            </Col>

            
          </Row>
        </Container>
      </Layout>
    </>
  );
}
