import React, { useEffect } from "react";
import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  SubmitDeleteRequest,
  profileshare,
} from "../Redux/Actions/Adminactions";
import { useParams } from "react-router-dom";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  reason: Yup.string().required("Please select a reason"),
  otherReason: Yup.string().when("reason", {
    is: "6",
    then: Yup.string().required("Please specify the reason"),
  }),
});

export default function DeleteAccountweb() {
  const BASE_URL = process.env.REACT_APP_FILE_BASE_URL;

  const dispatch = useDispatch();
  const params = useParams();
  console.log(params);

  return (
    <div style={{ marginTop: 50 }}>
      <Container>
        <Formik
          validationSchema={validationSchema}
          initialValues={{ email: "", reason: "", otherReason: "" }}
          onSubmit={async (values, {resetForm}) => {
            console.log(values);

            try {
              const data = await dispatch(
                SubmitDeleteRequest({
                  email: values?.email,
                  reason: values?.reason,
                  otherReason: values?.otherReason,
                })
              );
              console.log(data);
              if (data?.payload?.status === 200) {
                toast.success(data?.payload?.data?.message);
                resetForm();
              }
            } catch (error) {
              console.log(error);
            }
          }}
        >
          {({ handleSubmit, values, handleChange, setFieldValue,resetForm}) => (
            <Form>
              <div className="delte-acc-web">
                <div className="delete-acc-web-top">
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      name="email"
                      type="text"
                      placeholder="Type Here"
                      value={values?.email}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </Form.Group>
                  {/* <p>is subscribed to our mailing list</p> */}
                </div>
                <div className="delete-acc-web-bottom mt-4">
                  <h3>Account Delete Request</h3>
                  {/* <p>
              To help us improve our services , We Would be grateful if you
              could tell us why:
            </p> */}
                  <Form.Select
                    aria-label="Default select example"
                    className="mb-3"
                    name="reason"
                    value={values?.reason}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.value !== "4") {
                        setFieldValue("otherReason", "");
                      }
                    }}
                  >
                    <option>Please select reason</option>
                    <option value="1">
                    I don’t like URush
                    </option>
                    <option value="2">I can’t use URush</option>
                    <option value="3">
                    I found love
                    </option>
                    <option value="4">
                      Others
                    </option>
                  </Form.Select>
                  <ErrorMessage
                    name="reason"
                    component="div"
                    className="text-danger"
                  />
                    {values.reason === "4" && (
                  <Form.Group
                    className="mb-3"
                    
                    controlId="exampleForm.ControlTextarea1"
                  >
                  <Form.Label>Please specify reason</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="otherReason"
                      value={values?.otherReason}
                      onChange={handleChange}
                    />
                      <ErrorMessage name="otherReason" component="div" className="text-danger" />
                  </Form.Group>
                    )}
                  
                  <button type="button" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </div>
  );
}
